import React, { Fragment } from "react";
import { Typography } from "@material-ui/core";

import Highlight from "react-highlight.js";
import Comments from "../../Components/Comments";
import PostReply from "../../Components/PostReply";
import { onPostUpdateHandler, inlineCodeStyle } from "./common.js";

const groupId = 45;

export default ({ user, title, usingLightTheme }) => {
  const [commentState, setComments] = React.useState({
    comments: [],
    isFetching: true
  });

  return (
    <Fragment>
      <Typography variant="h5">{title}</Typography>

      <Typography>Asynchronous operations like file IO, network traffic & database operations can be simplified with coroutines by turning asynchronous callbacks into a synchronous flow and makes the calling code much easier to reason about. For this example we are going to make an asynchronous file IO library, to keep it simple we just allow opening and reading asynchronously a file so the calling code will not be blocked by it. Done in pre C++20:</Typography>
      <Highlight language="c++">
{`struct AsyncFileIOLibrary {
  void asyncReadFile(std::function<void()> callback) {
    std::lock_guard guard(mutex);
    work = [this, callback]() {
      for (std::string line; std::getline(outfile, line);) {
        lines.push_back(line);
      }
      callback();
    };
  }

  ~AsyncFileIOLibrary() {
    done = true;
    thread.join();
  }

private:
  std::fstream outfile;
  std::vector<std::string> lines;
  std::atomic<bool> done{ false };
  std::function<void()> work;
  std::mutex mutex;

  std::thread thread{ [this] {
    while (!done) {
      std::unique_lock lk(mutex);
      if (work) {
        auto copy = work;
        work = nullptr;
        lk.unlock();
        copy();
      }
      std::this_thread::yield();
    }
  } };
};

std::atomic<bool> finished{ false };
void readModifyWriteAsync(AsyncFileIOLibrary& lib) {
  lib.asyncOpenFile([&lib]() {
    lib.asyncReadFile([]() { // do something with lines
      finished = true;
    });
  });
}

int main() {
  AsyncFileIOLibrary lib;
  readModifyWriteAsync(lib); // non-blocking
  while (!finished) {
    // ... do other operations
  }
}`}
</Highlight>
<Typography>This doesn’t look so bad, but would become unwieldly with multiple callbacks which is quite commonly (and aptly) known as callback hell, we might also need to pass variables:</Typography>
<Highlight language="c++">
{`lib.asyncOpenFile([&lib]() {
  lib.asyncReadFile([&lib]() {
    // do something with lines
    lib.asyncWriteFile([]() {
      finished = true;
    });
  });
});`}
</Highlight>
<Typography>It would be much nicer if we could write this more intuitively, perhaps like this:</Typography>
<Highlight language="c++">
{`Task readModifyWriteAsync(AsyncFileIOLibrary& lib) {
  co_await lib.asyncOpenFile();
  co_await lib.asyncReadFile();
  finished = true;
}`}
</Highlight>
<Typography>Which is exactly what coroutines provide us using otherwise the same syntax:</Typography>
<Highlight language="c++">
{`auto asyncReadFile() {
  struct Awaiter {
    AsyncFileIOLibrary& lib;
    bool await_ready() const noexcept { return false; }
    void await_resume() const noexcept {}
    void await_suspend(std::coroutine_handle<> handle) {
      std::lock_guard guard(lib.mutex);
      lib.work = [handle, this]() mutable {
        std::string line;
        for (; std::getline(lib.outfile, line);) {
          lib.lines.push_back(line);
        }
        handle.resume();
      };
    }
  };
  return Awaiter{ *this };
};`}
</Highlight>
<Typography>Full example can be seen below:</Typography>
<Highlight language="c++">
{`#include <coroutine>
#include <iostream>
#include <thread>

struct AsyncFileIOLibrary {
	~AsyncFileIOLibrary() {
		done = true;
		thread.join();
	}

	auto async_open_file() {
		struct awaiter {
			AsyncFileIOLibrary& lib;
			bool await_ready() const noexcept { return false; }
			void await_resume() const noexcept { }
			void await_suspend(std::coroutine_handle<> handle) {
				std::lock_guard guard(lib.mutex);
				lib.work = [handle, &lib = lib]() mutable {
					lib.outfile.open("dummy.txt", std::fstream::app);
					handle.resume();
				};
			}
		};
		return awaiter { *this };
	}

	auto async_read_file() {
		struct awaiter {
			AsyncFileIOLibrary& lib;
			bool await_ready() const noexcept { return false; }
			void await_resume() const noexcept { }
			void await_suspend(std::coroutine_handle<> handle) {
				std::lock_guard guard(lib.mutex);
				lib.work = [handle, this]() mutable {
					std::string line;
					for (; std::getline(lib.outfile, line);) {
						lib.lines.push_back(line);
					}
					handle.resume();
				};
			}
		};
		return awaiter { *this };
	};

	std::fstream outfile;
	std::vector<std::string> lines;
	std::atomic<bool> done { false };
	std::function<void()> work;
	std::mutex mutex;

	std::thread thread { [this] {
		while (!done) {
			std::unique_lock lk(mutex);
			// Check if work is ready
			if (work) {
				auto copy = work;
				work = nullptr;
				lk.unlock();
				copy();
			}
			std::this_thread::yield();
		}
	} };
};

struct Task {
	struct promise_type {
		std::suspend_never initial_suspend() { return {}; };
		std::suspend_never final_suspend() noexcept { return {}; };
		void unhandled_exception() {};
		void return_void() { }
		auto get_return_object() { return Task {}; };
	};
};

std::atomic<bool> finished { false };

Task read_modify_write_async(AsyncFileIOLibrary& lib) {
	co_await lib.async_open_file();
	co_await lib.async_read_file();
	finished = true;
}

int main() {
	AsyncFileIOLibrary lib;
	read_modify_write_async(lib); // non-blocking
	while (!finished) {
		// ... do other operations
	}
	std::cout << "Done" << std::endl;
}`}
</Highlight>

      <Comments groupId={groupId} user={user} usingLightTheme={usingLightTheme} commentState={commentState} setComments={setComments} />
      <PostReply
        groupId={groupId}
        user={user}
        replyId={0}
        usingLightTheme={usingLightTheme}
        postHandler={(newComment) => onPostUpdateHandler(user, groupId, commentState.comments, setComments, newComment)}
      />
    </Fragment>
  );
};
