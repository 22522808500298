import { makeStyles } from "@material-ui/core/styles";

export const onPostUpdateHandler = (user, groupId, comments, setComments, newCommentData) => {
	let copy = comments;
	let newComment = newCommentData;
	newComment.userId = user.id;
	newComment.groupId = groupId;
	newComment.updatedAt = new Date();
	newComment.modifiedAt = new Date();
	newComment.name = user.username;
	newComment.avatarURL = user.avatarURL;
	newComment.points = user.points;
	newComment.isBanned = user.isBanned;
	newComment.isAdmin = user.isAdmin;

	copy.push(newComment);
	setComments({
		comments: copy.filter((comment) => comment.id != -1),
		isFetching: false
	});
};

// This doesn't actually do anything, it is used inside index.css.
export const inlineCodeStyle = makeStyles((theme) => ({
  inline: {
    display: "inline"
  },
}));

