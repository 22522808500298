import React, { Fragment } from "react";
import { Typography } from "@material-ui/core";

import Highlight from "react-highlight.js";
import Comments from "../../Components/Comments";
import PostReply from "../../Components/PostReply";
import { onPostUpdateHandler, inlineCodeStyle } from "./common.js";

const groupId = 30;

export default ({ user, title, usingLightTheme }) => {
  const [commentState, setComments] = React.useState({
    comments: [],
    isFetching: true
  });

  const classes = inlineCodeStyle ();

  return (
    <Fragment>
      <Typography variant="h5">{title}</Typography>

      <Typography>Creating a standard’s compliant view is non-trivial even for the simplest of views. So how do we go about designing our own view, which can be piped as well. Every view in the standard derives from <Highlight language="c++" className={classes.inline}>std::ranges::view_interface</Highlight>, it provides a solid base of functions filled in already so that is a good place to start. As this process is not easy, I will demonstrate an already existing standard view, rather than try to create a new one because it will be easier to test that it is working correctly and simple enough which can be used to build upon other views. Below is a bare-bones view which models <Highlight language="c++" className={classes.inline}>ranges::drop_view</Highlight> with some details omitted for now (full version can be found at the end):</Typography>
      <Highlight language="c++">
{`template <std::ranges::view V>
struct my_drop_view : public std::ranges::view_interface<my_drop_view<V>> {
  constexpr my_drop_view(
  V base, std::ranges::range_difference_t<V> count) :
  base_(base),
  count(count) { }

  constexpr auto begin() const {
    return std::ranges::next(std::ranges::begin(base_), count, std::ranges::end(base_));
  }
  constexpr auto end() const { return std::ranges::end(base_); }

private:
  V base_ = V();
  std::ranges::range_difference_t<V> count;
};`}
</Highlight>
<Typography><Highlight language="c++" className={classes.inline}>my_drop_view</Highlight> will drop the first count elements from a view. It is recommended to derive from <Highlight language="c++" className={classes.inline}>std::ranges::view_interface</Highlight>, although not strictly required. The view needs an underlying view because it doesn’t own anything, this is passed in the constructor as base. Most views will need this, the exception is range generators like <Highlight language="c++" className={classes.inline}>iota_view</Highlight> We also want to know the number of elements to drop. We also need begin and end iterators. Now this will allow us to compose a view inside of another view:</Typography>
<Highlight language="c++">
{`// Compose views
std::ranges::iota_view i(1, 10);
my_drop_view d1(i, 5); 
std::ranges::take_view t1(d1, 3); // even pass it to another
for (auto i : t1) {
  std::cout << i; // 789
}`}
</Highlight>
<Typography>This does not allow us to pipe it though (compiler error):</Typography>
<Highlight language="c++">
{`for (auto i : std::ranges::iota_view(1, 10) | views::my_drop(3)){ }`}
</Highlight>
<Typography>For this we need to create a range adaptor closure object.</Typography>
<Highlight language="c++">
{`struct my_drop_view_fn {
  template <typename E, typename F>
  constexpr auto operator()(E&& e, F&& f) const {
    return my_drop_view { std::forward<E>(e), std::forward<F>(f) };
  }

  template <typename C> constexpr auto operator()(C c) const {
    return [c = std::move(c)](auto&& r) mutable {
      return my_drop_view { std::forward<decltype(r)>(r),
        std::move(c) };
    };
  }
};

template <std::ranges::viewable_range Range, typename C>
constexpr auto operator|(Range&& lhs, C&& rhs) {
  return std::forward<C>(rhs)(std::forward<Range>(lhs));
}

namespace views {
  constexpr my_drop_view_fn my_drop;
}`}
</Highlight>
<Typography>Wrapping it inside of a views namespace is done for clarities sake. The pipe operator <Highlight language="c++" className={classes.inline}>operator|</Highlight> is what accepts a viewable range and allows our drop_view to be piped from other views. This now allows us to compile and run the following:</Typography>
<Highlight language="c++">
{`for (auto i : std::ranges::iota_view(1, 10) | views::my_drop(3)) {
  std::cout << i; // 456789
}`}
</Highlight>
<Typography>But we cannot currently append other range adaptors to our custom drop view such as:</Typography>
<Highlight language="c++">
{`std::ranges::iota_view(1, 100) | views::my_drop(3) | std::ranges::reverse`}
</Highlight>
<Typography>Or</Typography>
<Highlight language="c++">
{`  std::ranges::iota_view i(1, 10);
  const my_drop_view d1(i, 5);
  const std::ranges::take_view t1(d1, 3);`}
</Highlight>
<Typography>For this we need to add some more details to the <Highlight language="c++" className={classes.inline}>my_drop_view</Highlight> range adaptor. These are base(), size(), default constructor. For completeness we should also add a deduction guide and cache the begin iterator for non-random access iterators. Please see below for the complete code. </Typography>
<Highlight language="c++">
{`#include <algorithm>
#include <concepts>
#include <iostream>
#include <list>
#include <ranges>
#include <vector>

// Every standard implementation will implement this but it is not
// exposed so we must create our own simple-view concept
template <typename Range>
concept simple_view = std::ranges::view<_Range>&& std::ranges::range<const Range> &&
std::same_as<std::ranges::iterator_t<Range>, std::ranges::iterator_t<const Range>> &&
std::same_as<std::ranges::sentinel_t<Range>, std::ranges::sentinel_t<const Range>>;

// This is specific (taken from NanoRange approach)
template <bool IsRandomAccess, typename> struct drop_view_cache { };
template <typename I> struct drop_view_cache<false, I> {
  std::optional<I> cached {};
};

// Dervies from view_interface. Also derives from drop_view_cache
// to hold a cache value, this step is optional and can be implemented
// in other ways.
template <std::ranges::view V>
struct my_drop_view
: public std::ranges::view_interface<my_drop_view<V>>,
  private drop_view_cache<std::ranges::random_access_range<V>,
  std::ranges::iterator_t<V>> {
  // Non random access ranges cache the begin to save recalculation.
  static constexpr bool needs_cached_begin = !std::ranges::random_access_range<V>;

  // Default constructor needed for views using this as a base
  my_drop_view() = default;

  // Constructor
  constexpr my_drop_view(
  V base, std::ranges::range_difference_t<V> count) :
  base_(base), count(count) { }

  // base() method may be required for other views using this as a base
  constexpr V base() const& requires std::copy_constructible<V> {
    return base_;
  }
  constexpr V base() && { return std::move(base_); }

  // The start of the iterator
  constexpr auto begin() const
  requires std::ranges::random_access_range<const V> {
    return std::ranges::next(
    std::ranges::begin(base_), count, std::ranges::end(base_));
  }

  constexpr auto begin() requires(
  !(simple_view<V> && std::ranges::random_access_range<V>)) {
    if constexpr (!needs_cached_begin) {
      // Calculate on the fly
      return std::ranges::next(
      std::ranges::begin(base_), count, std::ranges::end(base_));
    } else {
      // Calculate value if not already and then cache it.
      auto& c = this->cached;
      if (!c.has_value()) {
        c = std::ranges::next(
        std::ranges::begin(base_), count, std::ranges::end(base_));
      }
      return *c;
    }
  }

  // End is based on the base end
  constexpr auto end() { return std::ranges::end(base_); }
  constexpr auto end() const { return std::ranges::end(base_); }

  // The number of elements in this range. Subtracts the count of the
  // underlying view from the count
  constexpr auto size() const requires std::ranges::sized_range<V> {
    const auto s = std::ranges::size(base_);
    const auto c = static_cast<decltype(s)>(count);
    return s < c ? 0 : s - c;
  }

  constexpr auto size() requires std::ranges::sized_range<const V> {
    return static_cast<const my_drop_view<V>&>(*this).size();
  }

private:
  V base_ = V();
  std::ranges::range_difference_t<V> count;
};

// Deduction guide
template <typename Range>
my_drop_view(Range&&, std::ranges::range_difference_t<Range>)
-> my_drop_view<std::views::all_t<Range>>;

// Range adaptor closure object
struct my_drop_view_fn {
  template <typename E, typename F>
  constexpr auto operator()(E&& e, F&& f) const {
    return my_drop_view { std::forward<E>(e), std::forward<F>(f) };
  }

  template <typename C> constexpr auto operator()(C c) const {
    // Requires a clousre object storing the drop count
    return [c = std::move(c)](auto&& r) mutable {
      return my_drop_view { std::forward<decltype(r)>(r),
        std::move(c) };
    };
  }
};

template <std::ranges::viewable_range Range, typename C>
constexpr auto operator|(Range&& lhs, C&& rhs) {
  return std::forward<C>(rhs)(std::forward<Range>(lhs));
}

// Add it to views namespace for consistency with ranges::views namespace alias
namespace views {
constexpr my_drop_view_fn my_drop;
}

int main() {
  const std::list<int> list { 1, 2, 3, 4, 5, 6, 7, 8, 9, 10 };

  my_drop_view d1(list, 5);
  std::ranges::take_view t1(d1, 3);

  for (auto i : t1) {
    std::cout << i; // do something
  }
  std::cout << std::endl;

  for (auto i : std::ranges::iota_view(1, 100) | views::my_drop(3) |
                std::views::take(2) | std::views::reverse) {
    // do something
  }
}`}
</Highlight>
      <Comments groupId={groupId} user={user} usingLightTheme={usingLightTheme} commentState={commentState} setComments={setComments} />
      <PostReply
        groupId={groupId}
        user={user}
        replyId={0}
        usingLightTheme={usingLightTheme}
        postHandler={(newComment) => onPostUpdateHandler(user, groupId, commentState.comments, setComments, newComment)}
      />
    </Fragment>
  );
};
