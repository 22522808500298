import React, { Fragment } from "react";
import { Typography } from "@material-ui/core";

import Highlight from "react-highlight.js";
import Comments from "../../Components/Comments";
import PostReply from "../../Components/PostReply";
import { onPostUpdateHandler, inlineCodeStyle } from "./common.js";

const groupId = 17;

export default ({ user, title, usingLightTheme }) => {
  const [commentState, setComments] = React.useState({
    comments: [],
    isFetching: true
  });

  const classes = inlineCodeStyle ();

  return (
    <Fragment>
      <Typography variant="h5">{title}</Typography>
      <Typography>Templates are very powerful but often a source of headaches when instantiations fail. Before we talk about the power of concepts, we need to look back and understand why they have been introduced. Take this template which requires that the <Highlight language="c++" className={classes.inline}>value_type</Highlight> nested member (all standard containers have this) be present to get access to the underlying type, e.g <Highlight language="c++" className={classes.inline}>{`std::vector<int>::value_type`}</Highlight> would return <Highlight language="c++" className={classes.inline}>int</Highlight>:</Typography>
      <Highlight language="c++">
        {`template<typename T>
void func() {
  T::value_type internalType;
}`}
      </Highlight>
      <Typography>{`Trying to call this function with a template argument which doesn't satisfy the constraints, such as a primitive type:`}</Typography>
      <Highlight language="c++">{`func<int>(); // int has no value_type nested member`}</Highlight>
      <Typography>{`It generates a very long error and obscure error message with MSVC:`}</Typography>
      <Highlight>
        {`concepts.cpp(153): error C2825: 'T': must be a class or namespace when followed by '::'
concepts.cpp(158): note: see reference to function template instantiation 'void func<int>(void)' being compiled
concepts.cpp(153): error C2510: 'T': left of '::' must be a class/struct/union
concepts.cpp(153): error C2065: 'value_type': undeclared identifier
concepts.cpp(153): error C2146: syntax error: missing ';' before identifier 'internalType'
concepts.cpp(153): error C2065: 'internalType': undeclared identifier`}
      </Highlight>
      <Typography>When using a correct type which has the <Highlight language="c++" className={classes.inline}>value_type</Highlight> member such as <Highlight language="c++" className={classes.inline}>std::vector</Highlight> it works fine:</Typography>
      <Highlight language="c++">{`func<std::vector<int>>(); // Compiles successfully`}</Highlight>
      <Typography>{`There's nothing in the interface of `}<Highlight language="c++" className={classes.inline}>func()</Highlight> that tells us it is expecting something with a <Highlight language="c++" className={classes.inline}>value_type</Highlight> nested member, so the compiler has trouble giving me an informed error as it must scan the template tokens during instantiation. Imagine this function is very long, the error message can get very long and intimidating. There is a process by which we can assign constraints by making use of a process more commonly known technique called SFINAE (Substitution Failure Is Not An Error) and this was typically done using <Highlight language="c++" className={classes.inline}>std::enable_if</Highlight> since C++11. Since C++17 it became a bit easier with the introduction of <Highlight language="c++" className={classes.inline}>std::void_t</Highlight>:</Typography>
      <Highlight language="c++">
        {`#include <type_traits>
template<typename T, typename=void> // Primary template 
struct has_value_type : std::false_type { };

// Partial specialization which resolves to has_value_type<T, void> if T has a value_type nested member.
template<typename T>
struct has_value_type <T, std::void_t<typename T::value_type>>: std::true_type { };
 
// Function which has SFINAED out types without the nested value_type member
template<typename T, typename = std::enable_if_t<has_value_type<T>::value>>
void func() {
  T::value_type myType;
}`}
      </Highlight>
      <Typography>Trying to compile this now with a non-<Highlight language="c++" className={classes.inline}>value_type</Highlight> parameter gives the following error:</Typography>
      <Highlight language="c++">{`func<int> ();`}</Highlight>
      <Highlight language="c++">{`error C2672: 'func': no matching overloaded function found
error C2783: 'void func(void)': could not deduce template argument for '<unnamed-symbol>'`}</Highlight>
<Typography>{`So this is a bit better, but I'm still none the wiser what the issue is and it's quite a pain to a) remember how to do this, and it takes up a lot of space. This is where concepts released in C++20 have helped overcome with better error messages and sleeker syntax.`}</Typography>
      <Highlight language="c++">
        {`#include <concepts>
template<typename T>
concept has_value_type = requires {
  typename T::value_type;
};

template<typename T> requires has_value_type<T>
void func () { 
  T::value_type myType;
}`}
      </Highlight>
      <Typography>Now trying to compile it, the following MSVC error is given:</Typography>
      <Highlight language="c++">{`func<int> ();`}</Highlight>
      <Highlight language="c++">{`error C7602: 'func': the associated constraints are not satisfied`}</Highlight>
<Typography>{`At last! a templated error message which is understandable. Including`} <Highlight language="c++" className={classes.inline}>{`<concepts>`}</Highlight> {`is all you need. The constraints can be used for class templates, function templates or member functions of class templates there's no excuse. The following gcc has more specific info:`}</Typography>
      <Highlight language="c++">
        {`error: use of function ‘void func() [with T = int]’ with unsatisfied constraints
|  func<int>();
|            ^
concepts.cpp:8:6: note: declared here
| void func() {
|      ^~~~
note: constraints not satisfied
In instantiation of ‘void func() [with T = int]’:
required from here
required for the satisfaction of ‘has_value_type<T>’ [with T = int]
`}
      </Highlight>
      <Comments groupId={groupId} user={user} usingLightTheme={usingLightTheme} commentState={commentState} setComments={setComments} />
      <PostReply
        groupId={groupId}
        user={user}
        replyId={0}
        usingLightTheme={usingLightTheme}
        postHandler={(newComment) => onPostUpdateHandler(user, groupId, commentState.comments, setComments, newComment)}
      />
    </Fragment>
  );
};
