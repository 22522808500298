import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { InputBase, Typography, IconButton, AppBar, Toolbar, Avatar, Grid, Button, Menu, MenuItem } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import SettingsBrightnessIcon from "@material-ui/icons/SettingsBrightness";
import { Link as RouterLink } from "react-router-dom";
import Config from "./config.js";

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("lg")]: {
      display: "none"
    }
  },
  root: {
    flexGrow: 1
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1
  }
}));

export default (props) => {
  const classes = useStyles();

  //	const [loggedIn, SetLoggedIn] = React.useState(false);

  const onMenuClick = () => {
    props.setMobileOpen(!props.mobileOpen);
  };

  const onLightDark = () => {
    props.setUsingLightTheme(!props.usingLightTheme);
  };

  const handleLogoutClick = () => {
    props.setUser(null);
    window.open(`${Config.serverUrl}/auth/logout`, "_self");
  };

  const handleAvatarClick = () => {
    window.open("/auth/logout", "_self");
  };

  const handleGoogleLogin = () => {
    // Authenticate using via passport api in the backend
    // Open google login page
    // Upon successful login, a cookie session will be stored in the client
    window.open(`${Config.serverUrl}/auth/google`, "_self");
  };

  const handleGithubLogin = () => {
    // Authenticate using via passport api in the backend
    // Open github login page
    // Upon successful login, a cookie session will be stored in the client
    window.open(`${Config.serverUrl}/auth/github`, "_self");
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        <IconButton onClick={onMenuClick} edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
          <MenuIcon />
        </IconButton>
        <div className={classes.root}>
          <Grid container spacing={1} direction="row" justify="space-between" alignItems="center">
            <Grid item>
              <Grid container spacing={1} direction="row" alignItems="center">
                <Grid item>
                  <Avatar to="/" component={RouterLink} src="/Logo_56.png" variant="square" />
                </Grid>
                <Grid item>
                  <Typography variant="h6">Universe</Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              {!props.user ? (
                <Button
                  variant="contained"
                  onClick={handleClick}
                  color="secondary"
                >
                  Login
                </Button>
              ) : (
                <Grid container spacing={1} direction="row" alignItems="center">
                  <Grid item>
                    <Avatar to={"/profile?id=" + props.user.id} component={RouterLink} alt="avatar" src={props.user.avatarURL} />
                  </Grid>
                  <Grid item>
                    <Button onClick={handleLogoutClick} variant="contained" color="secondary">
                      Logout
                    </Button>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </div>
        {/* Not using themes yet
         <IconButton onClick={onLightDark} color="inherit" aria-label="menu">
          <SettingsBrightnessIcon />
        </IconButton>*/}
      </Toolbar>

      <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={handleGoogleLogin}>Google</MenuItem>
        <MenuItem onClick={handleGithubLogin}>Github</MenuItem>
      </Menu>
    </AppBar>
  );
};
