import React, { Fragment } from "react";
import { Typography, Link } from "@material-ui/core";

import Highlight from "react-highlight.js";
import Comments from "../../Components/Comments";
import PostReply from "../../Components/PostReply";
import { onPostUpdateHandler, inlineCodeStyle } from "./common.js";

const groupId = 23;

export default ({ user, title, usingLightTheme }) => {
  const [commentState, setComments] = React.useState({
    comments: [],
    isFetching: true
  });

  const classes = inlineCodeStyle ();

  return (
    <Fragment>
      <Typography variant="h5">{title}</Typography>

      <Typography>A lot of people shy away from templates, they are effectively being created behind the scenes now, for instance in C++14 generic/polymorphic lambdas were introduced:</Typography>
      <Highlight language="c++">
        {`auto myLambda = [](auto x) {
  return x;
};
myLambda (2);
myLambda ("two");
`}
</Highlight>
<Typography>This demonstrates the power of type erasure, this created a temporary template class behind the scenes and created instantiations for the arguments where it was used. However you cannot use auto in as many places as we would like such as a parameter in a function.</Typography>
<Highlight language="c++">
void myFunc (auto x); // compile error auto cannot be used
</Highlight>
<Typography>Arguably this syntax is no better than the raw template usage, and actually the inventor of C++ said:</Typography>
<Typography>"I often explain that auto can be seen as the least constrained concept. For example:</Typography>
<Highlight language="c++">
  void f(auto); // first proposed in 2002!
</Highlight>
<Typography>is a function that can take a value of any type as an argument. This declaration is equivalent to:</Typography>
<Highlight language="c++">
{`template<typename T> void f(T);`}
</Highlight>
<Typography>so typename is the name of the least constrained concept." [1]</Typography>
<Typography>However there is a way to combine this auto syntax with concepts presenting the abbreviated function template:</Typography>
<Highlight language="c++">
{`template<std::integral T>
T divideBy2(T in) {
  return in / 2;
}`}
</Highlight>
<Typography>This can also be written as follows without template syntax:</Typography>
<Highlight language="c++">
{`std::integral auto divideBy2 (std::integral auto in) {
  return in / 2;
}`}
</Highlight>
<Typography>{`If you’ve ever had to write a HTML document with C++ syntax in the file then you know how annoying those angle brackets can be, replacing them with `}<Highlight className={classes.inline}>{`&lt;`}</Highlight> and <Highlight className={classes.inline}>{`&gt;`}</Highlight> otherwise they are interpreted as HTML tags. Reviewers who despise templates get pleased too even though they are still created behind the scenes, but don’t tell them that :D.</Typography>
<br />
1 - <Link color="secondary" href ="http://www.open-std.org/jtc1/sc22/wg21/docs/papers/2017/p0694r0.pdf">http://www.open-std.org/jtc1/sc22/wg21/docs/papers/2017/p0694r0.pdf</Link>
<br />
      <Comments groupId={groupId} user={user} usingLightTheme={usingLightTheme} commentState={commentState} setComments={setComments} />
      <PostReply
        groupId={groupId}
        user={user}
        replyId={0}
        usingLightTheme={usingLightTheme}
        postHandler={(newComment) => onPostUpdateHandler(user, groupId, commentState.comments, setComments, newComment)}
      />
    </Fragment>
  );
};
