import React, { Fragment } from 'react'
import { ListItem, ListItemText, List, withStyles, Typography } from '@material-ui/core'
import { Link as RouterLink, useRouteMatch } from 'react-router-dom';
import { conceptsCppPages, rangesCppPages, coroutinesCppPages } from './EverydayCpp20/store'
import { makeStyles, useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  unused: {
    color: "#555"
  },
}));

export default props => {

  const classes = useStyles ();

  let match = useRouteMatch();

  const StyledListItem = withStyles((theme) => ({
    root: {
    backgroundColor: theme.palette.background.paper,
    color: 'white',
    }, 
  }))(ListItem)

  const list = (chapter, cppPages) => (<Fragment><Typography variant="h5">{chapter}</Typography>
  <List>
      {cppPages.map(({ title, route }) => 
        <StyledListItem to={`${match.url}/${route}`} component={RouterLink} button key={title}>
          <ListItemText primary={title} />
        </StyledListItem>
      )}
    </List></Fragment>);

	return <Fragment>
  <Typography className={classes.unused}>Data structures</Typography>
  <Typography className={classes.unused}>Algorithms</Typography>
  {list ("Concepts", conceptsCppPages)}
  {list ("Ranges", rangesCppPages)}
  <Typography className={classes.unused}>Concurrency</Typography>
  <Typography className={classes.unused}>Templates</Typography>
  {list ("Coroutines", coroutinesCppPages)}
  <Typography className={classes.unused}>Modules</Typography>
  <Typography className={classes.unused}>Three-way comparison</Typography>
  <Typography className={classes.unused}>Other</Typography>
	</Fragment>
}