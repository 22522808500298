import React, { Fragment } from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import SocialIcons from "./Components/SocialIcons";

const useStyles = makeStyles((theme) => ({
  footer: {
    padding: theme.spacing(1)
  }
}));

export default (props) => {
  const classes = useStyles();
  return (
    <Fragment>
      <div className={classes.footer}>
        <Typography variant="body2">C++ Universe, Copyright &copy; - {new Date().getFullYear()} - Wesley Shillingford </Typography>
        <SocialIcons />
      </div>
    </Fragment>
  );
};
