import React, { Fragment } from "react";
import { Typography, Box } from "@material-ui/core";

import Highlight from "react-highlight.js";
import Comments from "../../Components/Comments";
import PostReply from "../../Components/PostReply";
import { onPostUpdateHandler, inlineCodeStyle } from "./common.js";

const groupId = 18;

export default ({ user, title, usingLightTheme }) => {
  const [commentState, setComments] = React.useState({
    comments: [],
    isFetching: true
  });

  const classes = inlineCodeStyle ();

  return (
    <Fragment>
      <Typography variant="h5">{title}</Typography>
      <Typography>A constraint is a sequence of logical operations and operands that specifies requirements on template arguments. They can either appear as the bodies of concepts (see below) or within requires-expressions. There are 3 types of constraints listed and explained below:</Typography>
      <br />
      <Typography><Box className={classes.inline} fontWeight="fontWeightBold">1- Conjunctions</Box> are formed using the logical <Highlight language="c++" className={classes.inline}>{`&&`}</Highlight> operator:</Typography>
<Highlight language="c++">
{`template <class T>
concept unsignedIntegral = std::is_integral_v<T> && 
                           std::is_unsigned_v<T>;`}
</Highlight>
<Typography>Although the above uses 2 boolean variable templates, it can combine multiple concepts as well. The standard already provides:</Typography>
<Highlight language="c++">
{`namespace std {
  template <class T>
  concept integral = is_integral_v<T>;
}`}
</Highlight>
<Typography>So it could be written as follows:</Typography>
<Highlight language="c++">
{`template <class T>
concept unsignedIntegral = std::integral<T> && 
                           std::is_unsigned_v<T>;
`}
</Highlight>
<Typography>The conjunctions are evaluated left to right, and the template arguments are only substituted after the left side of the conjunction succeeds. The definition of a concept must appear at namespace scope.</Typography>
<br />
<Typography><Box className={classes.inline} fontWeight="fontWeightBold">2- Disjunctions</Box> are formed by using the <Highlight language="c++" className={classes.inline}>{`||`}</Highlight> operator:</Typography>
<Highlight language="c++">
{`template <class T = void>
requires std::equality_comparable<T> || std::same_as<T, void>
class equal_to;
`}
</Highlight>
<Typography>Here we use the requires expression on a class template. Similarly to conjunctions they are evaluated left to right and template arguments are only substituted if the left side of the disjunction fails.</Typography>
<br />
<Typography><Box className={classes.inline} fontWeight="fontWeightBold">3 - Atomic constraints</Box> are expressions which must result to exactly bool after template substitution, no implicit conversion is permitted:</Typography>
<Highlight language="c++">
{`template<class T> concept Con = T::f ();
struct S1 {
  static constexpr bool f () {
    return true;
  }
};

struct S2 {
  static constexpr int f () {
    return 1;
  }
};

static_assert(Con<S1>); // Const expression that evaluates to true
static_assert(Con<S2>); // Compiler error
`}
</Highlight>
<Typography>Combined we can utilise all aspects to check that it is an unsigned integral (which isn’t a short) or has appropriate <Highlight language="c++" className={classes.inline}>{`T::f()`}</Highlight> operation evaluating to bool without implicit conversion:</Typography>
<Highlight language="c++">
{`template <class T>
concept ComplexConcept = (unsignedIntegral<T> &&
                         !std::same_as<unsigned short, T>) ||
                         T::f ();

static_assert (ComplexConcept<unsigned short>); 	// error
static_assert (ComplexConcept<unsigned int>); 	  // compiles
static_assert (ComplexConcept<S1>); 			        // compiles
`}
</Highlight>

      <Comments groupId={groupId} user={user} usingLightTheme={usingLightTheme} commentState={commentState} setComments={setComments} />
      <PostReply
        groupId={groupId}
        user={user}
        replyId={0}
        usingLightTheme={usingLightTheme}
        postHandler={(newComment) => onPostUpdateHandler(user, groupId, commentState.comments, setComments, newComment)}
      />
    </Fragment>
  );
};
