import React, { Fragment } from "react";
import { Typography, Box } from "@material-ui/core";

import Highlight from "react-highlight.js";
import Comments from "../../Components/Comments";
import PostReply from "../../Components/PostReply";
import { onPostUpdateHandler, inlineCodeStyle } from "./common.js";

const groupId = 19;

export default ({ user, title, usingLightTheme }) => {
  const [commentState, setComments] = React.useState({
    comments: [],
    isFetching: true
  });

  const classes = inlineCodeStyle ();

  return (
    <Fragment>
      <Typography variant="h5">{title}</Typography>

<Typography>The keyword requires can be used to begin a requires expression which describes constraints on some template arguments evaluating to a boolean value. When more than 1 requirement is required, they are separated with a semi-colon. There are 4 different types of requirements: Simple requirements, type requirements, compound requirements & nested requirements. Requirements may refer to the template parameters that are in scope, to the local parameters introduced in the parameter-list, or to any other declarations that are visible from the enclosing context.</Typography>
<br />
<Typography><Box fontWeight="fontWeightBold">1 - Simple requirement</Box></Typography>
<Typography>This is the simplest requirement (hence the same), which is just an arbitrary expression statement which checks language correctness. In the following example this checks that
a + b is a valid expression which will compile.</Typography>
<Highlight language="c++">
{`template<typename T>
concept Addable = requires (T a, T b) {
  a + b;
};
static_assert (Addable<int>);
static_assert (!Addable<const char*>);`}
</Highlight>
<Typography>The parameter list <Highlight language="c++" className={classes.inline}>(T a, T b)</Highlight> is a comma-separated list of parameters like in a function declaration, but default arguments are not allowed. These parameters are only used to aid in specifying requirements, they have no storage or lifetime like normal variables would have.</Typography>
<br />
<Typography><Box fontWeight="fontWeightBold">2 - Type requirements</Box></Typography>
<Typography>A type requirement is when typename is used with a type; this is used to verify that the type exists. Whether it be checking that a certain named nested type exists, or that a class template specialization names a type, or that an alias template specialization names a type. A type requirement naming a class template specialization does not require the type to be complete.</Typography>
<Highlight language="c++">
{`struct MyStruct { using type = int; };

template<class T>
class S;

// Class template specialization, used in type requirement below.
template<>
class S<MyClass>;

template<typename T>
concept TypeConcept = requires {
  typename T::type;  // Has this nested member name
  typename S<T>;     // Has this class template specialization
};

static_assert (TypeConcept<MyStruct>);`}
</Highlight>
<Typography><Box fontWeight="fontWeightBold">3 - Compound requirement</Box></Typography>
<Typography>Compound requirements are surrounded by curly braces. The first compound statement below is similar to a simple expression but can be made <Highlight language="c++" className={classes.inline}>noexcept</Highlight>. The second compound statement uses the optional return type requirement, which compares the <Highlight language="c++" className={classes.inline}>decltype</Highlight> of the result of <Highlight language="c++" className={classes.inline}>x + 1</Highlight>, which is of type <Highlight language="c++" className={classes.inline}>int</Highlight>. The substitution happens on the left-hand side, however the types may not always be as you'd think.</Typography>
<Highlight language="c++">
{`template<typename T>
concept C = requires(T x) {
  // the expression x + 2 must be valid and not throw an exception
  {x + 2} noexcept;

  // The expression x + 1 must be valid AND
  // std::same_as<decltype((x + 1)), T> must be satisfied
  {x + 1} -> std::same_as<T>;
};`}` 
</Highlight>
<Typography><Box fontWeight="fontWeightBold">4 - Nested requirements</Box></Typography>
<Typography>Nested requirements use the requires keyword followed by a constraint expression, which like all concepts needs to evaluate to a boolean.</Typography>
<Highlight>
{`template<typename T>
concept NestedConcept = requires(T x) {

  // Uses requires inside of the sequence of requirements
  requires std::same_as<T*, decltype(&x)>;
  requires std::is_integral<T>;
};`}`
</Highlight>
<Typography>They can of course all be combined. As you can see, you aren’t limited to a single parameter in the parameter list either, actually they are all optionally: </Typography>
<Highlight language="c++">
{`struct MyStruct {
  using type = size_t;
  size_t operator+ (size_t x);
};

template <typename T>
concept CombinationConcept = requires (T x, size_t n) {
  x + n; // Simple requirement
  typename T::type;  // Type, has nested member name
  { T() } noexcept;  // Compound, has noexcept default constructor
  requires std::same_as<T*, decltype(&x)>; // Nested
};`}
</Highlight>

      <Comments groupId={groupId} user={user} usingLightTheme={usingLightTheme} commentState={commentState} setComments={setComments} />
      <PostReply
        groupId={groupId}
        user={user}
        replyId={0}
        usingLightTheme={usingLightTheme}
        postHandler={(newComment) => onPostUpdateHandler(user, groupId, commentState.comments, setComments, newComment)}
      />
    </Fragment>
  );
};
