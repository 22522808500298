import Books from './Pages/Books'
import Courses from './Pages/Courses'
import VideoLessons from './Pages/VideoLessons'
import EverydayCpp20 from './Pages/EverydayCpp20'
import ThirdParty from './Pages/ThirdParty'
import About from './Pages/About'
import Home from './Pages/Home'
import Jobs from './Pages/Jobs'
import Recommendations from './Pages/Recommendations'
import CheatSheets from './Pages/CheatSheets'
import Highscores from './Pages/Highscores'
import Quiz from './Pages/Quiz'

import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import InfoIcon from '@material-ui/icons/Info';
import SpeedIcon from '@material-ui/icons/Speed';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import HomeIcon from '@material-ui/icons/Home';
import WorkIcon from '@material-ui/icons/Work';
import SportsEsportsIcon from '@material-ui/icons/SportsEsports';
import DirectionsIcon from '@material-ui/icons/Directions';
import SportsKabaddiIcon from '@material-ui/icons/SportsKabaddi';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import ListAltIcon from '@material-ui/icons/ListAlt';

export const pages = [
	{
		title: 'Home',
		route: '/',
		Comp: Home,
		Icon: HomeIcon
	},
	{
		title: 'C++20 Items',
		route: '/EverydayCpp20',
		Comp: EverydayCpp20,
		Icon: ViewModuleIcon
	},
/*	{
		title: 'Courses',
		route: '/Courses',
		Comp: Courses,
		Icon: AssignmentIcon
	},
	{
		title: 'Video Lessons',
		route: '/VideoLessons',
		Comp: VideoLessons,
		Icon: OndemandVideoIcon
	},
	{
		title: 'Books',
		route: '/Books',
		Comp: Books,
		Icon: ImportContactsIcon
	},*/
	/*{
		title: 'Learn C++ in 1 hour',
		route: '/LearnCpp',
		Comp: LearnCpp,
		Icon: SpeedIcon
	},*/
/*	{
		title: 'Cheat Sheets',
		route: '/CheatSheets',
		Comp: CheatSheets,
		Icon: ListAltIcon
	},*/
/*	{
		title: 'Games',
		route: '/Games',
		Comp: Games,
		Icon: SportsEsportsIcon
	}*/
]

export const socialPages = [
/*{
		title: 'Competitions',
		route: '/Competitions',
		Comp: Competitions,
		Icon: SportsKabaddiIcon
	},*/
/*{
		title: 'Quiz',
		route: '/Quiz',
		Comp: Quiz,
		Icon: SportsKabaddiIcon
	},
	{
		title: 'Jobs',
		route: '/Jobs',
		Comp: Jobs,
		Icon: WorkIcon
	},*/
/*	{
		title: 'Interview prep',
		route: '/Interviews',
		Comp: Interviews,
		Icon: WorkIcon
	},*/
/*{
		title: 'Recommendations',
		route: '/Recommendations',
		Comp: Recommendations,
		Icon: DirectionsIcon
	},*/	
/*	{
		title: 'Highscores',
		route: '/Highscores',
		Comp: Highscores,
		Icon: DoneOutlineIcon
	},*/
	{
		title: 'Third Party',
		route: '/ThirdParty',
		Comp: ThirdParty,
		Icon: ContactSupportIcon
	},
	{
		title: 'About',
		route: '/About',
		Comp: About,
		Icon: InfoIcon
	},
]