import React, { Fragment } from "react";
import { Typography } from "@material-ui/core";

import Highlight from "react-highlight.js";
import Comments from "../../Components/Comments";
import PostReply from "../../Components/PostReply";
import { onPostUpdateHandler, inlineCodeStyle } from "./common.js";

const groupId = 31;

export default ({ user, title, usingLightTheme }) => {
  const [commentState, setComments] = React.useState({
    comments: [],
    isFetching: true
  });

  const classes = inlineCodeStyle ();

  return (
    <Fragment>
      <Typography variant="h5">{title}</Typography>

      <Typography>Pre C++20 the begin and end iterators used in algorithms must be the same type, with ranges in C++20 this is no longer the case. A new type of end iterator called a sentinel can be used instead to denote the end of a range. There is a special sentinel called <Highlight language="c++" className={classes.inline}>std::unreachable_sentinel</Highlight> which can be used with any weakly incrementable type to denote the upper bound of an unbounded interval. The most used example I’ve seen for this is to find the first occurrence of a character which is 100% guaranteed to be in a string. <Highlight language="c++" className={classes.inline}>std::ranges::find</Highlight> is generally implemented as follows:</Typography>
      <Highlight language="c++">{`template <std::input_iterator Iter, std::sentinel_for<Iter> Sent,
          class T, typename Proj = std::identity>
constexpr Iter find(Iter first, Sent last, const T& value, Proj proj = {}) {

  while (first != last && std::invoke(proj, *first) != value)
    ++first;

  return first;
}

const char* p = "hello world\\n123456789";
// \\n must be guaranteed to be in the above C string.
const char* newStart = find(p, std::unreachable_sentinel, '\\n');
std::cout << ++newStart << std::endl; // 123456789`}
</Highlight>
<Typography>In this scenario the <Highlight language="c++" className={classes.inline}>first != last</Highlight> comparison is wasted because we know we will not reach the end of the collection before it is found. <Highlight language="c++" className={classes.inline}>std::unreachable_sentinel</Highlight> always returns false, this allows compilers to do optimisations like removing the condition leaving the body of the function effectively like so:</Typography>
<Highlight language="c++">
{`while (std::invoke(__proj, *first) != value)
  ++first;

return first;`}
</Highlight>
<Typography>But be cautious, if this condition is not true then will get a buffer overflow so be careful! Creating your own sentinel is easy too, an example we can use is that when trying to copy a string, we would be tempted to use our trusty friendly algorithm <Highlight language="c++" className={classes.inline}>std::copy</Highlight>:</Typography>
<Highlight language="c++">
{`const char * string = “C++20 rocks”
std::vector<char> buffer;
std::copy(str, str + std::strlen(str), std::back_inserter(buffer));`}
</Highlight>
<Typography>But this requires 2 iterations over the string, from <Highlight language="c++" className={classes.inline}>strlen</Highlight> and <Highlight language="c++" className={classes.inline}>std::copy</Highlight>. Let’s create our own sentinel which will check if we are at the null terminator, saving a whole iteration:</Typography>
<Highlight language="c++">
{`struct str_sent {}; // sentinel 
bool operator==(const char* str, str_sent) { return *str == '\0'; }
std::ranges::copy(str, str_sent{}, std::back_inserter(buffer));`}
</Highlight>

      <Comments groupId={groupId} user={user} usingLightTheme={usingLightTheme} commentState={commentState} setComments={setComments} />
      <PostReply
        groupId={groupId}
        user={user}
        replyId={0}
        usingLightTheme={usingLightTheme}
        postHandler={(newComment) => onPostUpdateHandler(user, groupId, commentState.comments, setComments, newComment)}
      />
    </Fragment>
  );
};
