import React from "react";
import { ListItem, List, Divider, ListItemText, ListItemIcon, CssBaseline, Hidden, Drawer, Grid } from "@material-ui/core";
import { Link as RouterLink, Route, Switch } from "react-router-dom";

import { TwitterTimelineEmbed } from "react-twitter-embed";

import { pages, socialPages } from "./store";
import SocialIcons from "./Components/SocialIcons";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import Footer from "./Footer";
import Profile from "./Pages/Profile";
import Inception from "./Pages/Blog/Inception";

const drawerWidth = 260;

const useStyles = makeStyles((theme) => ({
  root1: {
    flexGrow: 1
  },
  root: {
    display: "flex"
  },
  drawer: {
    [theme.breakpoints.up("lg")]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth
  },
  spacing: {
    flexGrow: 1,
    padding: theme.spacing(3)
  },
  content: {
    flexGrow: 1,
    [theme.breakpoints.up("lg")]: {
      maxWidth: `calc(100% - ${drawerWidth}px)`
    },
    [theme.breakpoints.down("md")]: {
      maxWidth: `calc(100%)`
    }
  },
  sidebar: {
    backgroundColor: "#364150",
    padding: theme.spacing(2)
  },
  socialIcons: {
    padding: theme.spacing(1)
  }
}));

function Error() {
  return <h1>Page not found!</h1>;
}

export default ({ mobileOpen, setMobileOpen, user, usingLightTheme, error }) => {
  const classes = useStyles();
  const theme = useTheme();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const onHandlerClick = () => {
    if (mobileOpen) {
      handleDrawerToggle();
    }
  };

  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <Divider />
      <List>
        {pages.map(({ title, route, Icon }) => (
          <ListItem to={route} component={RouterLink} onClick={onHandlerClick} button key={title}>
            <ListItemIcon>
              <Icon />
            </ListItemIcon>
            <ListItemText primary={title} />
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {socialPages.map(({ title, route, Icon }) => (
          <ListItem to={route} component={RouterLink} onClick={onHandlerClick} button key={title}>
            <ListItemIcon>
              <Icon />
            </ListItemIcon>
            <ListItemText primary={title} />
          </ListItem>
        ))}
      </List>
      <Divider />
      <div className={classes.socialIcons}>
        <SocialIcons />
      </div>
    </div>
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden mdUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={"left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper
            }}
            ModalProps={{
              keepMounted: true // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden mdDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <div className={classes.root1}>
          <Grid container>
            <Grid item md={9} xs={12} className={classes.spacing}>
              <Switch>
                <Route path={"/profile"} render={(props) => <Profile {...props} user={user} title="Profile" />} />
                {pages.map(({ route, title, Comp }) => {
                  // TODO: Find out why I need exact for root.
                  if (route == "/") {
                    return (
                      <Route path={route} exact render={(props) => <Comp {...props} user={user} title={title} />} />
                    );
                  } else {
                    return <Route path={route} render={(props) => <Comp {...props} user={user} usingLightTheme={usingLightTheme} title={title} />} />;
                  }
                })}
                {socialPages.map(({ route, title, Comp }) => (
                  <Route path={route} render={(props) => <Comp {...props} title={title} />} />
                ))}
                <Route path={"/blog/inception"} render={(props) => <Inception {...props} title="C++ Universe Inception" />} />
                <Route component={Error} />
              </Switch>
            </Grid>
            <Grid item md={3} xs={12} className={classes.sidebar}>
              {/* The sidebars */}
              <Switch>
                <Route
                  render={() => (
                    <div>
                      {" "}
                      <TwitterTimelineEmbed sourceType="profile" screenName="cppuniverse" options={{ height: 400 }} />
                    </div>
                  )}
                />
              </Switch>
            </Grid>
          </Grid>
        </div>
        <Divider />
        <Footer />
      </main>
    </div>
  );
};
