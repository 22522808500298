import React, { Fragment } from "react";
import { Typography, Box, Link } from "@material-ui/core";

import Highlight from "react-highlight.js";
import Comments from "../../Components/Comments";
import PostReply from "../../Components/PostReply";
import { onPostUpdateHandler, inlineCodeStyle } from "./common.js";

const groupId = 27;

export default ({ user, title, usingLightTheme }) => {
  const [commentState, setComments] = React.useState({
    comments: [],
    isFetching: true
  });

  const classes = inlineCodeStyle ();

  return (
    <Fragment>
      <Typography variant="h5">{title}</Typography>

      <Typography>Views provide a way to "view" the contents of a range in various different ways without incurring heavy costs and leaving the range unmodified. Views are a special type of range which might wrap a pair of iterators or generate its elements on demand (<Highlight language="c++" className={classes.inline}>iota_view</Highlight>), among others. They must have constant time copy, move, and assignment operators. Most containers are then not classed as views since copying the container must copy its elements, which cannot usually be done in constant time [1]. Until C++20 there has been no way to simply iterate over a container in reverse, other than looping over reversed iterators, which is not a very pleasant experience!. Using the new <Highlight language="c++" className={classes.inline}>std::ranges::reverse_view</Highlight> in C++20 this is no longer a dream:</Typography>
      <Highlight language="c++">
{`std::vector vec { 2, 6, 4, 1 };
std::ranges::reverse_view rv{ vec };
for (auto reversed_elem : rv) {
  // 1 4 6 2
}`}
</Highlight>
<Typography>There are many views and it is worth going over some of the more common ones so you can know when they can come in handy.</Typography>
<Typography><Highlight language="c++" className={classes.inline}>take_view</Highlight> takes the first few elements of a range:</Typography>
<Highlight language="c++">
{`auto view = std::ranges::take_view (vec, 2); // take 2 elements`}
</Highlight>
<Typography><Highlight language="c++" className={classes.inline}>iota_view</Highlight> generates a sequence of elements, repeatedly incrementing an initial value.</Typography>
<Typography>It is inclusive with the left parameter and exclusive on the right, [1, 5) in this case:</Typography>
  <Highlight language="c++">
{`for (auto i : std::ranges::iota_view { 1, 5 }) {
  // 1 2 3 4 
}`}
</Highlight>
<Typography><Highlight language="c++" className={classes.inline}>join_view</Highlight> flattens out a range:</Typography>
<Highlight language="c++">
{`vector vs{ "hello"s, " "s, "ranges"s };
for (char ch : std::ranges::join_view(vs)) {
  std::cout << ch; // hello ranges
}`}
</Highlight>
<Highlight language="c++">
{`// Flatten a 2x2 matrix
std::array<std::array<int, 2>, 2> mat{ { 1, 0}, { 4, 1 }, { 5, 3 } };
for (auto i : std::ranges::join_view(mat)) {
  // 1 0 4 1 5 3
}`}
</Highlight>
<Typography><Highlight language="c++" className={classes.inline}>transform_view</Highlight> transforms each element of a range:</Typography>
<Highlight language="c++">
{`vector v{ 1, 2, 3 };
std::ranges::transform_view times_ten {v, [](int i) {return i*10;}};
std::ranges::copy(vi, std::ostream_iterator<int>(std::cout, ", "));`}
</Highlight>
<Typography><Box textAlign="center" fontStyle="italic" fontWeight="fontWeightBold" paddingBottom={1}>10, 20, 30</Box></Typography>
<Typography><Highlight language="c++" className={classes.inline}>split_view</Highlight> splits a container by a delimiter:</Typography>
<Highlight language="c++">
{`std::string str("I am groot");
std::ranges::split_view words(str, ' '); // split on spaces
for (auto const & word : words) {
  for (auto ch : word) {
    std::cout << ch;
  }
  std::cout << "_";
}`}
</Highlight>
<Typography><Box textAlign="center" fontStyle="italic" fontWeight="fontWeightBold" paddingBottom={1}>I_am_groot_</Box></Typography>
<Typography>An advantage is that you also cannot form a view around an r-value.</Typography>
<Typography><Highlight language="c++" className={classes.inline}>filter_view</Highlight> allows you to apply a predicate to the results returned:</Typography>
<Highlight language="c++">
{`auto is_even = [](auto i) { return i % 2 == 0; };
std::vector v{ 1, 2, 3, 4 };
for (int i : std::ranges::filter_view (v, is_even)) {
  // 2 4
}`}
</Highlight>
<Typography>Now that we know a few views we can start combining any of the ones which are not range generators like <Highlight language="c++" className={classes.inline}>iota_view</Highlight>:</Typography>
<Highlight language="c++">
{`std::vector v { 1, 2, 3 };
std::ranges::transform_view tx_view (v, [](auto i) { return i*10; });
std::ranges::reverse_view reversed_transformed_view (tx_view);

for (auto i : reversed_transformed_view) {
  // 30 20 10
}`}
</Highlight>
<Typography>Remember containers are not views, but <Highlight language="c++" className={classes.inline}>transform_view</Highlight> expects a view, so what is going on here? Well it turns out that it is implicitly wrapped in a <Highlight language="c++" className={classes.inline}>std::ranges::ref_view</Highlight> which turns a container into a view, much akin to <Highlight language="c++" className={classes.inline}>std::reference_wrapper</Highlight>. The type of <Highlight language="c++" className={classes.inline}>reversed_transformed_view</Highlight> is actually:</Typography>
<Highlight language="c++">
{`std::cout << type_name (decltype (revered_transformed_view));`}
</Highlight>
<Typography><Box textAlign="center" fontStyle="italic" fontWeight="fontWeightBold" paddingBottom={1}>{`ranges::reverse_view<ranges::transform_view<ranges::ref_view<vector<int>>>`}</Box></Typography>
<Typography>The composed views are part of the type, this allows more compile time optimizations! There are also versions inside the <Highlight language="c++" className={classes.inline}>std::views</Highlight> namespace which overload operator| permit piping together, the equivalent version would be:</Typography>
<Highlight language="c++">
{`for (auto v | std::views::transform_view ([]() { return i * 10 }
            | std::views::reverse) {
  // 30 20 10
}`}
</Highlight>
<Typography><Highlight language="c++" className={classes.inline}>std::views</Highlight> is an alias to <Highlight language="c++" className={classes.inline}>std::ranges::views</Highlight></Typography>
<br />
1 - <Link color="secondary" href="http://www.open-std.org/jtc1/sc22/wg21/docs/papers/2018/p0896r4.pdf">http://www.open-std.org/jtc1/sc22/wg21/docs/papers/2018/p0896r4.pdf</Link>
<br />
      <Comments groupId={groupId} user={user} usingLightTheme={usingLightTheme} commentState={commentState} setComments={setComments} />
      <PostReply
        groupId={groupId}
        user={user}
        replyId={0}
        usingLightTheme={usingLightTheme}
        postHandler={(newComment) => onPostUpdateHandler(user, groupId, commentState.comments, setComments, newComment)}
      />
    </Fragment>
  );
};
