import React, { Fragment } from 'react'
import { Typography } from '@material-ui/core'

export default ({title}) => {

	return <Fragment>
		<Typography variant="h5">{title}</Typography>
		<Typography>Blog post #1</Typography>
		<Typography>This site was created to educate everyone and build fun projects. It is still in progress</Typography>
	</Fragment>
}