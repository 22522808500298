import React, { Fragment } from "react";
import { Typography, TableContainer, TableRow, TableCell, Paper, Link } from "@material-ui/core";

import Highlight from "react-highlight.js";
import Comments from "../../Components/Comments";
import PostReply from "../../Components/PostReply";
import { onPostUpdateHandler, inlineCodeStyle } from "./common.js";

const groupId = 22;

export default ({ user, title, usingLightTheme }) => {
  const [commentState, setComments] = React.useState({
    comments: [],
    isFetching: true
  });

  const classes = inlineCodeStyle ();

  return (
    <Fragment>
      <Typography variant="h5">{title}</Typography>

      <Typography>As a reminder there are multiple places the requires keyword is used: the requires clause, the requires expression and in nested requirements, all variants shown below:</Typography>
      <Highlight language="c++">
{`template <class T>
concept MyConcept = requires (T a, T b) { // requires expression
  a + b;
  requires std::same_as<T*, decltype(&x)>; // nested requirement
}; 
 
template<class T>
void myFunc requires MyConcept<T>; // requires clause`}
</Highlight>
<Typography>What it possible is to combine the requires clause and requires expression together:</Typography>
<Highlight language="c++">
{`template<typename T>
void func () requires requires (T a, T b) {
  a + b;
};`}
</Highlight>
<Typography>You end up with <Highlight language="c++" className={classes.inline}>requires requires</Highlight> which in my opinion is quite confusing. This is also the opinion of the creator of C++ who says that requires requires is probably a code smell, and it reminds him of something hardcoded, which should be a named concept (e.g. <Highlight language="c++" className={classes.inline}>Addable</Highlight>) [1]</Typography>
<br />
<Typography>Had a different keyword been used for either the requires clause or expression, it may have more weight in using both, but currently it just seems too confusing to anyone looking at it.</Typography>
<br />
<Typography>Note: the requires clause can be put at the end of the template argument list or after the function declarator:</Typography>
<br />
<TableContainer component={Paper}>
  <TableRow>
  <TableCell>
  <Highlight language="c++" className={classes.inline}>
{`template<class T> requires std::integral<T>
 void find_prime (T num);`}
</Highlight>
</TableCell>
  <TableCell>
  <Highlight language="c++" className={classes.inline}>
{`template<class T> 
 void find_prime (T num) requires std::integral<T>;`}
</Highlight>
</TableCell>
  </TableRow>
</TableContainer>
<br />
<Typography>An empty requires expression is ill-formed:</Typography>
<Highlight language="c++">
{`template <class T>
concept EmptyConcept = requires {
  // error, must have at least 1 sequence requirement
};`}
</Highlight>
<br />
1 - <Link color="secondary" href="https://youtu.be/HddFGPTAmtU?t=3303">https://youtu.be/HddFGPTAmtU?t=3303</Link>
<br />
      <Comments groupId={groupId} user={user} usingLightTheme={usingLightTheme} commentState={commentState} setComments={setComments} />
      <PostReply
        groupId={groupId}
        user={user}
        replyId={0}
        usingLightTheme={usingLightTheme}
        postHandler={(newComment) => onPostUpdateHandler(user, groupId, commentState.comments, setComments, newComment)}
      />
    </Fragment>
  );
};
