import React, { Fragment } from 'react'
import { Typography, makeStyles, Card, CardMedia, CardActionArea, CardContent, Chip } from '@material-ui/core'

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
});

export default ({title}) => {

	const classes = useStyles();

	return <Fragment>
		<Typography>{title}</Typography>
    <Card className={classes.root}>
      <CardActionArea>
        <CardMedia
          className={classes.media}
          image="/Logo_512.jpg"
          title="Create your own cryptocurrency"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            Trading engine
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            Build a cryptocurrency matching engine
          </Typography>
						<Chip size="small" label="C++20" />
						<Chip size="small" label="Cryptocurrency" />
        </CardContent>
      </CardActionArea>
    </Card>
    <Card className={classes.root}>
      <CardActionArea>
        <CardMedia
          className={classes.media}
          image="/Logo_512.jpg"
          title="Create your own cryptocurrency"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            C++ Universe Coin, 3D CAD program. A game (flappy birds?), one which is popular right now would be good... Create our own language (LLVM). Create our own Operating system. Have a twitter poll to get followers? Giveaways. Raspberry PI/Arduino. Robotics
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            TODO
          </Typography>
						<Chip size="small" label="C++20" />
						<Chip size="small" label="Cryptocurrency" />
        </CardContent>
      </CardActionArea>
    </Card>		
	</Fragment>
}