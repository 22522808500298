import React, { Fragment } from "react";
import { Typography, Box } from "@material-ui/core";

import Highlight from "react-highlight.js";
import Comments from "../../Components/Comments";
import PostReply from "../../Components/PostReply";
import { onPostUpdateHandler, inlineCodeStyle } from "./common.js";

const groupId = 29;

export default ({ user, title, usingLightTheme }) => {
  const [commentState, setComments] = React.useState({
    comments: [],
    isFetching: true
  });

  const classes = inlineCodeStyle ();

  return (
    <Fragment>
      <Typography variant="h5">{title}</Typography>

      <Typography>Lets take the first 2 even elements using the code below:</Typography>
      <Highlight language="c++">
{`auto isEven = [](auto i) { return i % 2 == 0; };
std::vector vec { 1, 2, 3, 8 };
for (int i : vec | std::views::filter(isEven) | std::views::take(2) {
  std::cout << i; // 1, 2
}`}
</Highlight>
<Typography>This is fine, but again there is a hidden daemon, filter will keep being called until the predicate is next true even if the number of items has been taken. This is because it only breaks out once a view interface has returned that it is at end and this only happens on the next iteration. Adding some output statements, we can see it first hand:</Typography>
<Highlight language="c++">
{`for (int i : vec | std::views::filter([](auto t) {
  std::cout << t << " Filter" << std::endl;
  return isEven (t);
}) | std::views::take(1)) {
  std::cout << i << " For-loop body" << std::endl;
}`}
</Highlight>
<Typography variant="body2">
<Typography><Box textAlign="center" fontStyle="italic" fontWeight="fontWeightBold" paddingBottom={1}>1 Filter</Box></Typography>
<Typography><Box textAlign="center" fontStyle="italic" fontWeight="fontWeightBold" paddingBottom={1}>2 Filter</Box></Typography>
<Typography><Box textAlign="center" fontStyle="italic" fontWeight="fontWeightBold" paddingBottom={1}>2 For-loop body</Box></Typography>
<Typography><Box textAlign="center" fontStyle="italic" fontWeight="fontWeightBold" paddingBottom={1}>3 Filter</Box></Typography>
<Typography><Box textAlign="center" fontStyle="italic" fontWeight="fontWeightBold" paddingBottom={1}>8 Filter</Box></Typography>
</Typography>
<Typography>We already have all the elements we want so we should just stop once we’ve taken this element. Imagine if we had millions of odd numbers before the next even number, exaggerated but it’s just to get the point across. This can be achieved by storing the count and subtracting it away as the number in the loop body. It can be written as follows now:</Typography>
<Highlight language="c++">
{`auto takeCount = 1;
for (int i : vec | std::views::filter([](auto t) {
  std::cout << t << " Filter" << std::endl;
  return isEven (t);
}) | std::views::take(takeCount)) {
  std::cout << i << " For-loop body" << std::endl;
  if (--takeCount == 0)
    break;
}`}
</Highlight>
<Typography variant="body2">
<Typography><Box textAlign="center" fontStyle="italic" fontWeight="fontWeightBold" paddingBottom={1}>1 Filter</Box></Typography>
<Typography><Box textAlign="center" fontStyle="italic" fontWeight="fontWeightBold" paddingBottom={1}>2 Filter</Box></Typography>
<Typography><Box textAlign="center" fontStyle="italic" fontWeight="fontWeightBold" paddingBottom={1}>2 For-loop body</Box></Typography>
</Typography>
<Typography>We are free to modify <Highlight language="c++" className={classes.inline}>takeCount</Highlight> because <Highlight language="c++" className={classes.inline}>take_view</Highlight> copies the count. Out of interest, what if we use multiple takes?</Typography>
<Highlight language="c++">
{`for (auto i : vec | std::views::take(3) | std::views::take(4))`}
</Highlight>
<Typography>As expected the first one prevents more than 3 crossing over, giving just: 1 2 3</Typography>

      <Comments groupId={groupId} user={user} usingLightTheme={usingLightTheme} commentState={commentState} setComments={setComments} />
      <PostReply
        groupId={groupId}
        user={user}
        replyId={0}
        usingLightTheme={usingLightTheme}
        postHandler={(newComment) => onPostUpdateHandler(user, groupId, commentState.comments, setComments, newComment)}
      />
    </Fragment>
  );
};
