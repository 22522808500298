import React, { Fragment } from "react";
import { Typography } from "@material-ui/core";

import Highlight from "react-highlight.js";
import Comments from "../../Components/Comments";
import PostReply from "../../Components/PostReply";
import { onPostUpdateHandler, inlineCodeStyle } from "./common.js";

const groupId = 26;

export default ({ user, title, usingLightTheme }) => {
  const [commentState, setComments] = React.useState({
    comments: [],
    isFetching: true
  });

  const classes = inlineCodeStyle ();

  return (
    <Fragment>
      <Typography variant="h5">{title}</Typography>

      <Typography>Projection are another feature of ranges which allows member variables and functions to be used as the arguments to the comparator instead of the element of the range itself. Lets take the following simple structure:</Typography>
<Highlight language="c++">
{`struct Cheese {
  int id;
}`}
</Highlight>
<Typography>Traditionally (in pre-C++20 era) this would be sorted explicitly with a comparator by id, usually with a lambda, to provide a one-off way to access the id:</Typography>
<Highlight language="c++">
{`std::vector<Cheese> vec { /* ... */ };
std::sort(std::begin(vec), std::end(vec), [](auto& lhs, auto& rhs) {
  return lhs.id < rhs.id;
};`}
</Highlight>
<Typography>Using the ranges comparator in combination with a projection allows us to instead use the member directly:</Typography>
<Highlight language="c++">
{`// sort by id
std::ranges::sort(vec, std::ranges::less{}, &Cheese::id);`}
</Highlight>
<Typography>It also works with member functions.</Typography>
<Highlight language="c++">
{`class Cheese {
public:
  int GetId () const noexcept { return id; };
  int id;
}

std::ranges::sort(vec, std::ranges::greater {}, &Cheese::GetId);`}
</Highlight>
<Typography>The projection can actually be any invokable (remember <Highlight language="c++" className={classes.inline}>std::ranges::less</Highlight> is the default):</Typography>
<Highlight language="c++">
{`std::ranges::sort(vec, {}, [](auto & cheese) { return cheese.id; });`}
</Highlight>
<Typography>Has does all this work? The <Highlight language="c++" className={classes.inline}>std::ranges::sort</Highlight> algorithm at some point will invoke the comparator with results from subsequent invoking of the projections, like the following:</Typography>
<Highlight language="c++">
{`if (std::invoke(comp, std::invoke(proj, l), std::invoke(proj, r))) {`}
</Highlight>
<Typography><Highlight language="c++" className={classes.inline}>std::invoke</Highlight> operates differently depending on the number of arguments and what is passed in. The rules for <Highlight language="c++" className={classes.inline}>std::invoke (f, a1, a2, ...)</Highlight> are such that if the number of arguments is 1 and <Highlight language="c++" className={classes.inline}>f</Highlight> is a pointer to a member function then it is equivalent to <Highlight language="c++" className={classes.inline}>{`(*a1).*f`}</Highlight>, in the case of <Highlight language="c++" className={classes.inline}>{`&Cheese::id`}</Highlight> it would be <Highlight language="c++" className={classes.inline}>{`l->id`}</Highlight>, if it is a pointer to a member function then it is <Highlight language="c++" className={classes.inline}>(*a1(f))(a2, ...)</Highlight>, so <Highlight language="c++" className={classes.inline}>{`l->GetId ()`}</Highlight> otherwise it is <Highlight language="c++" className={classes.inline}>{`f(a1, a2 ...)`}</Highlight>. Confusing I know, but what matters is that the comparator when used with the above projections ends up being called as:</Typography>
<Highlight language="c++">
{`comp (l->id, r->id)
comp (l->GetId (), l->GetId ();`}
</Highlight>
<Typography>under the hood.</Typography>

      <Comments groupId={groupId} user={user} usingLightTheme={usingLightTheme} commentState={commentState} setComments={setComments} />
      <PostReply
        groupId={groupId}
        user={user}
        replyId={0}
        usingLightTheme={usingLightTheme}
        postHandler={(newComment) => onPostUpdateHandler(user, groupId, commentState.comments, setComments, newComment)}
      />
    </Fragment>
  );
};
