import React, { Fragment } from "react";
import { Typography, Box } from "@material-ui/core";

import Highlight from "react-highlight.js";
import Comments from "../../Components/Comments";
import PostReply from "../../Components/PostReply";
import { onPostUpdateHandler, inlineCodeStyle } from "./common.js";

const groupId = 44;

export default ({ user, title, usingLightTheme }) => {
  const [commentState, setComments] = React.useState({
    comments: [],
    isFetching: true
  });

  const classes = inlineCodeStyle ();

  return (
    <Fragment>
      <Typography variant="h5">{title}</Typography>

      <Typography>Event handling is very common in UI applications, coroutines can be used to simplify the notification and handling of event systems.</Typography>
      <Highlight language="c++">
{`int main() {
  Event myEvent;

  auto coroHandleEvent = [&myEvent]() -> EventTask {
    std::cout << "1 - Waiting for event" << std::endl;
    co_await myEvent;
    std::cout << "3 - Awoken" << std::endl;
  };

  coroHandleEvent();
  coroHandleEvent();
  // ... Can have multiple of these and in different threads

  std::cout << "2 - Mark event as set" << std::endl;
  myEvent.set();
}`}
</Highlight>
<Typography variant="body2">
<Typography><Box textAlign="center" fontStyle="italic" fontWeight="fontWeightBold" paddingBottom={1}>1 - Waiting for event</Box></Typography>
<Typography><Box textAlign="center" fontStyle="italic" fontWeight="fontWeightBold" paddingBottom={1}>1 - Waiting for event</Box></Typography>
<Typography><Box textAlign="center" fontStyle="italic" fontWeight="fontWeightBold" paddingBottom={1}>2 - Mark event as set</Box></Typography>
<Typography><Box textAlign="center" fontStyle="italic" fontWeight="fontWeightBold" paddingBottom={1}>3 – Awoken</Box></Typography>
<Typography><Box textAlign="center" fontStyle="italic" fontWeight="fontWeightBold" paddingBottom={1}>3 – Awoken</Box></Typography>

</Typography>
<Typography>For this example, we will use a simple task for <Highlight language="c++" className={classes.inline}>void</Highlight> returning coroutines:</Typography>
<Highlight language="c++">
{`struct EventTask {
  // promise_type is a required nested type
  struct promise_type {
    EventTask get_return_object() { return {}; }
    std::suspend_never initial_suspend() { return {}; }
    std::suspend_never final_suspend() noexcept { return {}; }
    EventAwaiter await_transform(Event& event);
    void return_void() {}
    void unhandled_exception() {}
  };
};
`}
</Highlight>
<Typography>The only thing of note is the <Highlight language="c++" className={classes.inline}>await_transform</Highlight>. This enables us to override what happens when <Highlight language="c++" className={classes.inline}>co_await</Highlight> is used with this task. In particular we use a custom awaiter for the event:</Typography>
<Highlight language="c++">
{`EventAwaiter EventTask::promise_type::await_transform(Event& event) {
  return EventAwaiter(event);
}`}
</Highlight>
<Highlight language="c++">
{`class Event {
public:
  void set() noexcept {
    if (!isSet) {
      isSet = true;
      for (auto& handle : handles) {
        // Resume all coroutines
        handle.resume();
      }
    }
  }
  bool isSet{ false };
  std::vector<std::coroutine_handle<event_task::promise_type>> handles;
};

class EventAwaiter {
public:
  explicit EventAwaiter(Event& event) noexcept : event(event) {}

  bool await_ready() const noexcept { return event.isSet; }
  bool await_suspend(
  std::coroutine_handle<event_task::promise_type> awaiter) noexcept {
    event.handles.push_back(awaiter);
    return true;
  }
  void await_resume() const noexcept {}

  Event& event;
};`}
</Highlight>
This works because a <Highlight language="c++" className={classes.inline}>co_await</Highlight> expression is transformed into the following:
<Highlight language="c++">
{`/* auto result = */ co_await myEvent
auto && __awaiter = myEvent;
if (!__awaiter.await_ready ()) {
	__awaiter.await_suspend (coroutine_handle);
}
/* auto result = */ __awaiter.await_resume ();`}
</Highlight>
<Typography>However <Highlight language="c++" className={classes.inline}>myEvent</Highlight> isn’t awaitable, which is why we declare <Highlight language="c++" className={classes.inline}>await_transform()</Highlight>. This turns <Highlight language="c++" className={classes.inline}>co_await expr</Highlight> turns into <Highlight language="c++" className={classes.inline}>co_await promise.await_transform(expr)</Highlight>.</Typography>
<Typography><Highlight language="c++" className={classes.inline}>operator co_await()</Highlight> could also have been implemented inside <Highlight language="c++" className={classes.inline}>myEvent</Highlight>.</Typography>
<br />
<Typography>The full listing can be seen below:</Typography>
<Highlight language="c++">
{`#include <coroutine>
#include <iostream>
#include <vector>

class EventAwaiter;
class Event;

// A simple task-class for void-returning coroutines.
struct EventTask {
	struct promise_type {
		EventTask get_return_object() { return {}; }
		std::suspend_never initial_suspend() { return {}; }
		std::suspend_never final_suspend() noexcept { return {}; }
		EventAwaiter await_transform(Event& event);
		void return_void() { }
		void unhandled_exception() { }
	};
};

class Event {
public:
	void set() noexcept {
		if (!isSet) {
			isSet = true;
			for (auto& handle : handles) {
				// Resume all coroutines
				handle.resume();
			}
		}
	}
	bool isSet { false };
	std::vector<std::coroutine_handle<EventTask::promise_type>> handles;
};

class EventAwaiter {
public:
	explicit EventAwaiter(Event& event) noexcept : event(event) { }

	bool await_ready() const noexcept { return event.isSet; }
	bool await_suspend(
	std::coroutine_handle<EventTask::promise_type> awaiter) noexcept {
		event.handles.push_back(awaiter);
		return true;
	}
	void await_resume() const noexcept { }

	Event& event;
};

EventAwaiter EventTask::promise_type::await_transform(Event& event) {
	return EventAwaiter(event);
}

int main() {
	Event myEvent;

	auto coroHandleEvent = [&myEvent]() -> EventTask {
		std::cout << "1 - Waiting for event" << std::endl;
		co_await myEvent;
		std::cout << "3 - Awoken" << std::endl;
	};

	coroHandleEvent();
	coroHandleEvent();
	// ... Can have multiple of these and in different threads

	std::cout << "2 - Mark event as set" << std::endl;
	myEvent.set();
}`}
</Highlight>

      <Comments groupId={groupId} user={user} usingLightTheme={usingLightTheme} commentState={commentState} setComments={setComments} />
      <PostReply
        groupId={groupId}
        user={user}
        replyId={0}
        usingLightTheme={usingLightTheme}
        postHandler={(newComment) => onPostUpdateHandler(user, groupId, commentState.comments, setComments, newComment)}
      />
    </Fragment>
  );
};
