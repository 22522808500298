import React, { Fragment } from 'react'
import TwitterIcon from '@material-ui/icons/Twitter';
import YoutubeIcon from '@material-ui/icons/YouTube';
import {makeStyles} from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  clickableIcon: {
    '&:hover': {
    color: theme.palette.primary.main,
		},
		cursor: "pointer"
  },	
}));

export default props => {
	const classes = useStyles ();
   return (
   	<Fragment>
			 <TwitterIcon className={classes.clickableIcon} onClick={() => window.location.href = "https://www.twitter.com/cppuniverse"} />
			 <YoutubeIcon className={classes.clickableIcon} onClick={() => window.location.href = "https://www.youtube.com/channel/UCS5joMkAN5-CrG1G-Q7QZKA"} />
		</Fragment>
)}

