export const quizQuestions = [];

quizQuestions.push({
	text0: "AAAAAAWhat is the correct answer here?\n\nhehehe",
	code0: `std::cout << "Penises" << std::endl\nsa`,
	code0Formatting: "c++", // this is the default if none is specified
	text1: "OpenCL C Kernel code",
	code1: "OpenCL kernel coding",
	code1Formatting: "OpenCL",
	multiChoiceQuestions: ["penis", "not_penis"]
	});

quizQuestions.push({
	text0: "111?",
	code0: `std::cout << "Hello Quiz" << std::endl`,
	code0Formatting: "c++", // this is the default if none is specified
	text1: "OpenCL 123123",
	code1: "OpenC12312",
	code1Formatting: "OpenCL",
	multiChoiceQuestions: ["the fook", "huh"]
});

quizQuestions.push({
	text0: "111?",
	code0: `std::cout << "Hello Quiz" << std::endl`,
	code0Formatting: "c++", // this is the default if none is specified
	multiChoiceQuestions: ["the fook", "huh"]
});

quizQuestions.push({
	text0: "111?",
	code0: `std::cout << "Hello Quiz" << std::endl`,
	code0Formatting: "c++", // this is the default if none is specified
	multiChoiceQuestions: ["the fook", "huh"]
});

quizQuestions.push({
	text0: "111?",
	code0: `std::cout << "Hello Quiz" << std::endl`,
	code0Formatting: "c++", // this is the default if none is specified
	multiChoiceQuestions: ["the fook", "huh"]
});

quizQuestions.push({
	text0: "111?",
	code0: `std::cout << "Hello Quiz" << std::endl`,
	code0Formatting: "c++", // this is the default if none is specified
	multiChoiceQuestions: ["the fook", "huh"]
});

quizQuestions.push({
	text0: "111?",
	code0: `std::cout << "Hello Quiz" << std::endl`,
	code0Formatting: "c++", // this is the default if none is specified
	multiChoiceQuestions: ["the fook", "huh"]
});

quizQuestions.push({
	text0: "111?",
	code0: `std::cout << "Hello Quiz" << std::endl`,
	code0Formatting: "c++", // this is the default if none is specified
	multiChoiceQuestions: ["the fook", "huh"]
});

quizQuestions.push({
	text0: "111?",
	code0: `std::cout << "Hello Quiz" << std::endl`,
	code0Formatting: "c++", // this is the default if none is specified
	multiChoiceQuestions: ["the fook", "huh"]
});

quizQuestions.push({
	text0: "111?",
	code0: `std::cout << "Hello Quiz" << std::endl`,
	code0Formatting: "c++", // this is the default if none is specified
	multiChoiceQuestions: ["the fook", "huh"]
});