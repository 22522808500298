import {quizQuestions as questionsCpp20_1} from "./store_cpp20_1";
import {quizQuestions as questionsConcepts_1} from "./store_concepts_1";

export const pages = [
  {
    title: "C++20 Quiz Part 1",
		questions: questionsCpp20_1,
		serverid: "5eb7fc413791b82507ef7098",
		route: "Cpp20_1"
	},
	{
    title: "C++20 Concepts Quiz",
		questions: questionsConcepts_1,
		serverid: "5eb7fc413791b82507ef7098",
		route: "Concepts_1"
  }
];
