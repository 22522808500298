import Item17 from './Item17'
import Item18 from './Item18'
import Item19 from './Item19'
import Item20 from './Item20'
import Item21 from './Item21'
import Item22 from './Item22'
import Item23 from './Item23'
import Item24 from './Item24'
import Item25 from './Item25'
import Item26 from './Item26'
import Item27 from './Item27'
import Item28 from './Item28'
import Item29 from './Item29'
import Item30 from './Item30'
import Item31 from './Item31'
import Item43 from './Item43'
import Item44 from './Item44'
import Item45 from './Item45'
import Item46 from './Item46'

export const conceptsCppPages = [
	{
		title: 'Item 17 - All templates should be constrained with concepts',
		route: 'ConceptsTemplates',
		Comp: Item17
	},
	{
		title: 'Item 18 - Compose custom concepts with constraints',
		route: 'ConceptsCustom',
		Comp: Item18
	},
	{
		title: 'Item 19 - Use requirements to constrain templates',
		route: 'ConceptsRequirements',
		Comp: Item19
	},
	{
		title: 'Item 20 - Prefer simple requirements to no-return compound requirements which aren\'t noexcept',
		route: 'ConceptsPrefSimple',
		Comp: Item20
	},
	{
		title: 'Item 21 - Consider expanding compound requirements when dealing with reference types',
		route: 'ConceptsExpandCompound',
		Comp: Item21
	},
	{
		title: 'Item 22 - Do not use "requires requires"',
		route: 'ConceptsNoRequiresRequires',
		Comp: Item22
	},
	{
		title: 'Item 23 - Prefer abbreviated function templates (concepts) over legacy template syntax',
		route: 'ConceptsPreferAbbr',
		Comp: Item23
	},
	{
		title: 'Item 24 - Avoid boolean values inside sequence requirements',
		route: 'ConceptsAvoidBool',
		Comp: Item24
	},
];

export const rangesCppPages = [
	{
		title: 'Item 25 - Use range algorithms instead of STL algorithms',
		route: 'RangesUseAlgos',
		Comp: Item25
	},
	{
		title: 'Item 26 - Use projections in favour of custom predicates',
		route: 'RangesUseProj',
		Comp: Item26
	},
	{
		title: 'Item 27 - Compose views when iterating over modified ranges is desired',
		route: 'RangesUseViews',
		Comp: Item27
	},
	{
		title: 'Item 28 - Ensure range adaptors are ordered correctly',
		route: 'RangesAdaptorOrder',
		Comp: Item28
	},
	{
		title: 'Item 29 - Break out early when the take view is used',
		route: 'RangesBreakTake',
		Comp: Item29
	},
	{
		title: 'Item 30 - Create custom composable views',
		route: 'RangesCustomViews',
		Comp: Item30
	},
	{
		title: 'Item 31 - Use sentinels to give optimisation hints to compilers',
		route: 'RangesSentinels',
		Comp: Item31
	},		
];

export const coroutinesCppPages = [
	{
		title: 'Item 43 - Prefer coroutines for generators (lazy sequences)',
		route: 'CoroutinesGenerators',
		Comp: Item43
	},
	{
		title: 'Item 44 - Prefer coroutines for event handling',
		route: 'CoroutinesEventHandling',
		Comp: Item44
	},
	{
		title: 'Item 45 - Prefer coroutines for async operations',
		route: 'CoroutinesAsync',
		Comp: Item45
	},
	{
		title: 'Item 46 - Wrap existing async APIs in coroutines',
		route: 'CoroutinesExistingAsync',
		Comp: Item46
	},
];
