import React, { Fragment } from "react";
import { Typography } from "@material-ui/core";

import Highlight from "react-highlight.js";
import Comments from "../../Components/Comments";
import PostReply from "../../Components/PostReply";
import { onPostUpdateHandler, inlineCodeStyle } from "./common.js";

const groupId = 46;

export default ({ user, title, usingLightTheme }) => {
  const [commentState, setComments] = React.useState({
    comments: [],
    isFetching: true
  });

  const classes = inlineCodeStyle ();

  return (
    <Fragment>
      <Typography variant="h5">{title}</Typography>

      <Typography>Sometimes APIs are bound to 3rd party libraries or they belong to the legacy side or the "do not touch" portion of the codebase. In which case it is still possible to use these with co-routines, it will just be done as a wrapper around the existing architecture, this also enables them to be used without coroutines should the need arise. Taking the previous example we just need to replace <Highlight language="c++" className={classes.inline}>await_suspend</Highlight> with the async call as before: </Typography>
      <Highlight language="c++">
{`struct Wrapper {
  auto asyncReadFile() {
    struct awaiter {
      AsyncFileIOLibrary& lib;
      bool await_ready() const noexcept { return false; }
      void await_resume() const noexcept {}
      void await_suspend(std::coroutine_handle<> handle) {
	      lib.async_read ([handle]() mutable {
	        handle.resume ();
        });
      }
    };
    return awaiter{ *this };
  };

AsyncFileIOLibrary lib;
};`}
</Highlight>
<Typography>Then call it like so, exactly as before:</Typography>
<Highlight language="c++">
{`Wrapper lib;
co_await lib.async_open_file();`}
</Highlight>

      <Comments groupId={groupId} user={user} usingLightTheme={usingLightTheme} commentState={commentState} setComments={setComments} />
      <PostReply
        groupId={groupId}
        user={user}
        replyId={0}
        usingLightTheme={usingLightTheme}
        postHandler={(newComment) => onPostUpdateHandler(user, groupId, commentState.comments, setComments, newComment)}
      />
    </Fragment>
  );
};
