import React, { Fragment } from "react";
import { Typography, Link } from "@material-ui/core";

import Highlight from "react-highlight.js";
import Comments from "../../Components/Comments";
import PostReply from "../../Components/PostReply";
import { onPostUpdateHandler, inlineCodeStyle } from "./common.js";

const groupId = 25;

export default ({ user, title, usingLightTheme }) => {
  const [commentState, setComments] = React.useState({
    comments: [],
    isFetching: true
  });

  const classes = inlineCodeStyle ();

  return (
    <Fragment>
      <Typography variant="h5">{title}</Typography>

<Typography>{`STL algorithms (there are > 100) are abstracted in a way which allows a variety of standard data structures including custom ones to utilise generic algorithms. Take a commonly used idiom of sorting, the `}<Highlight language="c++" className={classes.inline}>std::sort</Highlight> function takes 2 iterators and sorts the elements between them. Typically we want to sort the entire collection:</Typography>
<Highlight language="c++">
{`std::vector<int> v { 5, 4, 2 };
std::sort(std::begin (v), std::end (v)); // 2, 4, 5
`}
</Highlight>
<Typography>The intuition when I first tried using the STL algorithms at least was why couldn’t a vector be passed in. This is exactly what the constrained algorithms in C++20 have added, this can now be simplified with the new range-based overload:</Typography>
<Highlight language="c++">
std::ranges::sort(v); // 2, 4, 5
</Highlight>
<Typography>No more manually specifying the begin and end iterators explicitly, just pass the container. Lets see the function declarations for the STL algorithm (some others have been omitted): </Typography>
<Highlight language="c++">
{`namespace std {
  template <class Iter>, 
  constexpr void sort(Iter first, Iter last);

  template <class Iter, class Compare>, 
  constexpr void sort(Iter first, Iter last, Compare comp); 
}`}
</Highlight>
<Highlight language="c++" className={classes.inline}>std::sort</Highlight> has 2 overloads, one taking 2 iterators, the other has an additional comparator. The ranges library chooses instead to combine these declarations and use default parameters.
<Highlight language="c++">
{`namespace std::ranges {
  template<std::random_access_iterator I, std::sentinel_for<I> S,
          class Comp = ranges::less, class Proj = std::identity>
  requires sortable<I, Comp, Proj>
  constexpr I sort(I first, S last, Comp comp = {}, Proj proj = {});

  template<std::random_access_range R, class Comp = ranges::less<>, 
                                       class Proj = identity> 
  requires sortable<iterator_t<R>, Comp, Proj> 
  constexpr iterator_t<R> sort(R&& r, Comp comp = {}, Proj proj = {});
}`}
</Highlight>
<Typography>There are 2 overloads, the first one allows using familiar iterators except the end can be a completely different type now, known as a sentinel (see <Link color="secondary" href="RangesSentinels">Item 31</Link>) but you can quite happily use iterators too:</Typography>
<Highlight language="c++">
std::ranges::sort(std::begin(v), std::end(v)); // 2, 4, 5
</Highlight>
<Typography>We then move onto the comparator which is defaulted to <Highlight language="c++" className={classes.inline}>{`ranges::less<>`}</Highlight>, this is slightly different to <Highlight language="c++" className={classes.inline}>std::less</Highlight> as <Highlight language="c++" className={classes.inline}>std::ranges::less</Highlight> requires all six comparison operators <Highlight language="c++" className={classes.inline}>{`<, <=, >, >=, ==, !=`}</Highlight> to be valid (via the <Highlight language="c++" className={classes.inline}>totally_ordered_with</Highlight> constraint), but thanks to the spaceship operator <Highlight language="c++" className={classes.inline}>{`<=>`}</Highlight> this is less of an issue for new types. It also allows using proj as the element for the comparator, it is short for projections and covered in the next item.</Typography>

      <Comments groupId={groupId} user={user} usingLightTheme={usingLightTheme} commentState={commentState} setComments={setComments} />
      <PostReply
        groupId={groupId}
        user={user}
        replyId={0}
        usingLightTheme={usingLightTheme}
        postHandler={(newComment) => onPostUpdateHandler(user, groupId, commentState.comments, setComments, newComment)}
      />
    </Fragment>
  );
};
