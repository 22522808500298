import React, { Fragment } from "react";
import { Typography } from "@material-ui/core";

import Highlight from "react-highlight.js";
import Comments from "../../Components/Comments";
import PostReply from "../../Components/PostReply";
import { onPostUpdateHandler, inlineCodeStyle } from "./common.js";

const groupId = 21;

export default ({ user, title, usingLightTheme }) => {
  const [commentState, setComments] = React.useState({
    comments: [],
    isFetching: true
  });

  const classes = inlineCodeStyle ();

  return (
    <Fragment>
      <Typography variant="h5">{title}</Typography>

      <Typography>It's very difficult to control the type returned from a compound requirement when using the optional return type. This can become problematic when using reference types, or types which become references from using the special bracket version of <Highlight language="c++" className={classes.inline}>decltype((expression))</Highlight>. For instance:</Typography>
      <Highlight language="c++">
{`int x;
decltype (x); // returns int
decltype ((x)); // returns int&`}
</Highlight>
<Typography>So this expression would evaluate to true, because <Highlight language="c++" className={classes.inline}>{`{x}`}</Highlight> expands into <Highlight language="c++" className={classes.inline}>decltype((x))</Highlight>:</Typography>
<Highlight language="c++">
{`template<typename T>
concept C1 = requires(T x) {
  {x} -> std::same_as<T&>;
};`}
</Highlight>
<Typography>Taking a more practical example of something which returns a reference:</Typography>
<Highlight language="c++">
{`class Base { };
class Derived : public Base {
  Derived& Derived::operator++();
};

template<typename T>
concept C1 = requires(T x) {
  {++x} -> std::derived_from<Base>; // decltype((x)) returns Derived&
};
 
// This fails with error 'Base' is not a base of 'Derived&'
static_assert (C1<Derived>);`}
</Highlight>
<Typography>To solve this, we might, introduce a new concept which returns the reference, however this would become tedious, this is effectively the same as:</Typography>
<Highlight language="c++">
{`template<typename D, typename B>
concept is_derived_from_remove_ref = std::derived_from<std::remove_reference_t<D>, B>;`}
</Highlight>
<Typography>However, the standard says that it should be equivalent to:</Typography>
<Highlight language="c++">
{`template<typename T> concept C1 = requires(T x) {
  ++x;
  requires std::derived_from<decltype((++x)), Base>;
};`}
</Highlight>
<Typography>It does mean a bit of duplication for the expression, but saves from introducing one off concepts for this purpose. It is here we can add:</Typography>
<Highlight language="c++">{`requires std::derived_from<std::remove_reference_t <decltype((++x))>, Base>;`}</Highlight>

      <Comments groupId={groupId} user={user} usingLightTheme={usingLightTheme} commentState={commentState} setComments={setComments} />
      <PostReply
        groupId={groupId}
        user={user}
        replyId={0}
        usingLightTheme={usingLightTheme}
        postHandler={(newComment) => onPostUpdateHandler(user, groupId, commentState.comments, setComments, newComment)}
      />
    </Fragment>
  );
};
