import React from "react";
import Comment from "./Comment";
import Config from "../config";

export default ({user, commentState, setComments, groupId, usingLightTheme}) => {

  React.useEffect(() => {
    const url = `${Config.serverUrl}/comments?groupId=${groupId}`;
    fetch(url)
      .then((res) => res.json())
      .then((commentList) => {
        setComments({
          comments: commentList,
          isFetching: false
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const { comments, isFetching } = commentState;
  return isFetching ? "Loading..." : <Comment groupId={groupId} user={user} usingLightTheme={usingLightTheme} allComments={comments} setAllComments={setComments} />;
};
