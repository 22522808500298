import React, { Fragment } from "react";
import { Typography } from "@material-ui/core";

import Highlight from "react-highlight.js";
import Comments from "../../Components/Comments";
import PostReply from "../../Components/PostReply";
import { onPostUpdateHandler, inlineCodeStyle } from "./common.js";

const groupId = 20;

export default ({ user, title, usingLightTheme }) => {
  const [commentState, setComments] = React.useState({
    comments: [],
    isFetching: true
  });

  const classes = inlineCodeStyle ();

  return (
    <Fragment>
      <Typography variant="h5">{title}</Typography>

      <Typography>Take the following concept with a compound statement:</Typography>
      <Highlight language="c++">
{`template <class T>
concept Con = requires(T a) {
  { a.func (); } // compound statement, has func() function
}`}
</Highlight>
<Typography>This does not make use of the optional noexcept or the return type statement. This is functionally equivalent to this simple statement:</Typography>
<Highlight language="c++">
{`template <class T>
concept Con = requires(T t) {  
  t.func (); // simple statement
};`}
</Highlight>
<Typography>It helps to reduce noise, here’s an example using it with a structure:</Typography>
<Highlight language="c++">
{`struct S {
  void func ();
} 
static_assert (Con<S>); // compiles`}
</Highlight>
<Typography>Now if our concept wanted to specify that <Highlight language="c++" className={classes.inline}>func()</Highlight> must be <Highlight language="c++" className={classes.inline}>noexcept</Highlight>, then we must use a compound requirement as simple requirements do not provide this functionality:</Typography>
<Highlight language="c++">
{`template <class T>
concept Con = requires(T t) {  
  { t.func () } noexcept; // compound statement
};

static_assert (Con<S>); // error`}
</Highlight>
<Typography>Making the member function on the structure <Highlight language="c++" className={classes.inline}>noexcept</Highlight> works:</Typography>
<Highlight language="c++">
{`struct S {
  void func () noexcept;
}
static_assert (Con<S>); // compiles`}
</Highlight>

      <Comments groupId={groupId} user={user} usingLightTheme={usingLightTheme} commentState={commentState} setComments={setComments} />
      <PostReply
        groupId={groupId}
        user={user}
        replyId={0}
        usingLightTheme={usingLightTheme}
        postHandler={(newComment) => onPostUpdateHandler(user, groupId, commentState.comments, setComments, newComment)}
      />
    </Fragment>
  );
};
