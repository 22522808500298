import React, { Fragment } from "react";
import { Typography, Link } from "@material-ui/core";

import Highlight from "react-highlight.js";
import Comments from "../../Components/Comments";
import PostReply from "../../Components/PostReply";
import { onPostUpdateHandler, inlineCodeStyle } from "./common.js";

const groupId = 24;

export default ({ user, title, usingLightTheme }) => {
  const [commentState, setComments] = React.useState({
    comments: [],
    isFetching: true
  });

  const classes = inlineCodeStyle ();

  return (
    <Fragment>
      <Typography variant="h5">{title}</Typography>

      <Typography>Using concepts or helper variable templates can lead to subtle inside sequence requirements This can lead to subtle bugs, take for example:</Typography>
      <Highlight language="c++">
{`template<typename T>
concept C = requires {
  std::integral<T>;
};`}
</Highlight>
<Typography>From the outside this looks like it expects the concept to require that the type be an integral. However this compiles fine:</Typography>
<Highlight language="c++">
{`static_assert (C<const char*>); // compiles`}
</Highlight>
<Typography>Using the concept directly confirms it is not an integral:</Typography>
<Highlight language="c++">
{`static_assert (!std::integral<const char*>); // compiles`}
</Highlight>
<Typography>{`This is because as we saw in `}<Link color="secondary" href="ConceptsRequirements">Item 19</Link> {`there are 4 types of requirement: it is not a nested, type or compound requirement, therefore it must be a simple requirement. Simple requirements assert validity of an expression, `}<Highlight language="c++" className={classes.inline}>{`std::integral<const char*`}</Highlight> {`is a perfectly valid expression, however the result of it (false) is not actually used for anything. Making it a compound requirement doesn't work.`}</Typography>
<Highlight language="c++">
{`template<typename T>
concept C = requires {
  {std::integral<T>}; // same as the simple requirement
};`}
</Highlight>
<Typography>For this to work, it needs to be a nested requirement:</Typography>
<Highlight language="c++">
{`template<class T>
concept C = requires {
  requires std::integral<T>;
};

static_assert (!C<const char*>); //compiles
`}
</Highlight>
<Typography>However to avoid bugs like this in the future, we propose that it should be an atomic constraint in the concept body instead:</Typography>
<Highlight language="c++">
{`template<class T>
concept C = std::integral<T>;

static_assert (!C<const char*>); // compiles
`}
</Highlight>
<Typography>This avoids any doubt, and any further conjunctions/disjunctions/atomic constraints can be joined.</Typography>

      <Comments groupId={groupId} user={user} usingLightTheme={usingLightTheme} commentState={commentState} setComments={setComments} />
      <PostReply
        groupId={groupId}
        user={user}
        replyId={0}
        usingLightTheme={usingLightTheme}
        postHandler={(newComment) => onPostUpdateHandler(user, groupId, commentState.comments, setComments, newComment)}
      />
    </Fragment>
  );
};
