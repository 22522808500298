import React, { Fragment } from "react";
import { Typography } from "@material-ui/core";

import Highlight from "react-highlight.js";
import Comments from "../../Components/Comments";
import PostReply from "../../Components/PostReply";
import { onPostUpdateHandler, inlineCodeStyle } from "./common.js";

const groupId = 28;

export default ({ user, title, usingLightTheme }) => {
  const [commentState, setComments] = React.useState({
    comments: [],
    isFetching: true
  });

  const classes = inlineCodeStyle ();

  return (
    <Fragment>
      <Typography variant="h5">{title}</Typography>

      <Typography>Lets take a simple range adaptor (view) example:</Typography>
      <Highlight language="c++">
{`auto even = [](auto item) { return item % 2 == 0;};
auto square = [](auto item) { return item * item };
std::vector vecInt { 1, 2, 3, 4, 6, 12 };
for (int i : vec_int | std::views::filter(even)
                     | std::views::transform(square)) { }`}
</Highlight>
<Typography>If this is changed to:</Typography>
<Highlight language="c++">
{`for (int i : vecInt | std::views::transform(square)
                    | std::views::filter(even)) { }`}
</Highlight>
<Typography>While you won’t see a difference in the number of times the for body is called, behind the scenes this will perform slightly worse because the square function is called more times than necessary. This is because transform is now called for each element and then the filtering is done. We can filter out unwanted odd numbers first by using the original code. In this example we won’t notice a difference however this can have semantic changes too. If for example we wanted to filter numbers which were less than 10 before being squared.</Typography>
<Highlight language="c++">
{`auto lessThan10 = [](auto item) { return item < 10; };
for (int i : vecInt | std::views::transform(square)
                    | std::views::filter(lessThan10)) { }`}
</Highlight>
<Typography>This would actually only filter squared numbers less than 10, not numbers less than 10 before being squared. So make sure the order is correct.
When writing these out using the <Highlight language="c++" className={classes.inline}>*_view</Highlight> classes directly it’s not always easy to understand what is happening:</Typography>
<Highlight language="c++">
{`auto view = std::ranges::filter_view (std::ranges::transform_view (vecInt, square), even);`}
</Highlight>
<Typography>Is this filtering or transforming first? Reading from left to right it might seem like we are filtering then transforming, but this is not the case the expression is built from deep in the chain outwards. So I recommend using the piped variant as it is just easier to reason about. If needing to build it this way, break out the component views so that the order can be read from top downwards:</Typography>
<Highlight language="c++">
{`std::ranges::filter_view filtered_view (vecInt, even);
std::ranges::transform_view (filtered_view);`}
</Highlight>

      <Comments groupId={groupId} user={user} usingLightTheme={usingLightTheme} commentState={commentState} setComments={setComments} />
      <PostReply
        groupId={groupId}
        user={user}
        replyId={0}
        usingLightTheme={usingLightTheme}
        postHandler={(newComment) => onPostUpdateHandler(user, groupId, commentState.comments, setComments, newComment)}
      />
    </Fragment>
  );
};
