import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { List, ListItem, Divider, ListItemText, ListItemAvatar, Avatar, Typography, Button } from "@material-ui/core";
import "react-markdown-editor-lite/lib/index.css";
import PostReply from "./PostReply";
import Alert from "./Alert";
import ProcessCancelDialog from "./ProcessCancelDialog";
import { Link as RouterLink } from "react-router-dom";
import { CppMdEditor } from "./MDParser";
import Config from "../config.js";
import moment from "moment";

let deleteId;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%"
  },
  fonts: {
    fontWeight: "bold"
  },
  inline: {
    display: "inline"
  },
  reply: {
    marginTop: theme.spacing(1),

    [theme.breakpoints.up("lg")]: {
      marginLeft: theme.spacing(6),
    },
    [theme.breakpoints.down("md")]: {
      marginLeft: theme.spacing(4),
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: theme.spacing(3),
    },
    marginRight: theme.spacing(1),
//    marginBottom: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  //  border: "10px solid #222",
   },
  button: {
    marginLeft: theme.spacing(1)
  },
  editButton: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  updateCancelButton: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  listTop: {
    borderBottom: "1px solid #333",
//    borderBottomColor: "#333"
  },
  list: {
    marginBottom: theme.spacing(4),
    backgroundColor: "#444",
    border: "1px solid #222",
  },
  replyListTop: {
    borderBottom: "1px solid #333",
    // border: "1px solid #333",
  },
  replyList: {
    marginBottom: theme.spacing(2),
    backgroundColor: "#444",
    border: "1px solid #222",

  },
  "@global": {
    ".section-container": {
      background: "#555"
    }
  }
}));

const Comment = ({ groupId, user, allComments, setAllComments, usingLightTheme }) => {
  const classes = useStyles();

  const editHandler = (event, comment) => {
    event.preventDefault();

    let copy = allComments;
    for (var i in copy) {
      if (copy[i].id == comment.id) {
        copy[i].isEditing = true;
        copy[i].text = copy[i].mdEditor.getMdValue();

        copy[i].mdEditor.on("keydown", (event) => {
          if (event.keyCode === 9) {
            let copy = allComments;
            event.preventDefault();
            for (var i in copy) {
              if (copy[i].id == comment.id) {
                copy[i].mdEditor.insertText("\t", true, { start: 1, end: 1 });
              }
            }
          }
        });

        copy[i].mdEditor.setView({
          menu: true,
          md: true,
          html: false
        });
      }
    }

    setAllComments({ comments: copy.filter((comment) => comment.id != 2), isFetching: false });
  };

  const scrollToRef = (ref) => {
    window.scrollTo(0, ref.current.offsetTop); // + 150);
  };

  const [errOpen, setErrOpen] = React.useState(false);
  const [deleteOpen, setDeleteOpen] = React.useState(false);

  const onPostUpdateHandler = (comment, newCommentData) => {
    // Hide isReplying for the rest of comments
    let copy = allComments;
    for (var i in copy) {
      if (copy[i].id == comment.id) {
        copy[i].isReplying = false;
        break;
      }
    }

    // TODO: Use from common.js????

    let newComment = newCommentData;
    //    newComment.id = comment.id;
    newComment.userId = user.id;
    newComment.groupId = groupId; // Is necessary actually?
    newComment.updatedAt = new Date();
    newComment.modifiedAt = new Date();
    newComment.name = user.username;
    newComment.avatarURL = user.avatarURL;
    newComment.points = user.points;
    newComment.isBanned = user.isBanned;
    newComment.isAdmin = user.isAdmin;

    // Add the comment?
    copy.push(newComment);
    setAllComments({ comments: copy.filter((comment) => comment.id != -1), isFetching: false });
  };

  const updateHandler = (event, comment) => {
    let copy = allComments;
    for (var i in copy) {
      if (copy[i].id == comment.id) {
        const url = `${Config.serverUrl}/auth/comments?groupId=${groupId}&commentId=${comment.id}`;

        const el = comment.mdEditor.getMdElement();
        if (el) {
          console.log("mdelement");
          if (el.scrollHeight > 2000) {
            alert("Height is too much, please shorten");
          } else {
            fetch(url, {
              method: "POST",
              mode: "cors",
              credentials: "include",
              headers: {
                "Content-Type": "text/plain",
                "Access-Control-Allow-Credentials": true
              },
              body: comment.mdEditor.getMdValue()
            })
              .then((response) => response.json())
              .then((response) => {
                if (response.hasProperty("error")) {
                  // Update text back to original due to an error
                  setErrOpen(true);
                } else {
                  let copy = allComments;
                  for (var i in copy) {
                    if (copy[i].id == comment.id) {
                      copy[i].isEditing = false;

                      copy[i].mdEditor.setView({
                        menu: false,
                        md: false,
                        html: true
                      });

                      setAllComments({ comments: copy.filter((comment) => comment.id != -1), isFetching: false });
                      break;
                    }
                  }
                }
              });
            // .catch (err) {

            //  }
          }
        }
        break;
      }
    }
  };

  const cancelHandler = (event, comment) => {
    let copy = allComments;
    for (var i in copy) {
      if (copy[i].id == comment.id) {
        copy[i].isEditing = false;

        copy[i].mdEditor.setView({
          menu: false,
          md: false,
          html: true
        });
        comment.mdEditor.setText(comment.text);
        break;
      }
    }

    setAllComments({ comments: copy.filter((comment) => comment.id != -1), isFetching: false });
  };

  const replyToReplyHandler = (event, comment) => {
    let copy = allComments;
    for (var i in copy) {
      if (copy[i].id == comment.replyId) {
        copy[i].isReplying = true;
        justHitReplyRef = true;
        // Add a > at the beginning of each line so that we can do multi-level quotes
        let lines = comment.text.split("\n");
        var newText = `> *From: ${comment.name}*  \n`;
        lines.forEach((line) => (newText += `> ${line}\n`));
        copy[i].initialText = newText + "\n";
      } else {
        // Turn the others off so that only 1 is shown
        copy[i].isReplying = false;
        copy[i].initialText = "";
      }
    }

    setAllComments({ comments: copy.filter((comment) => comment.id != -1), isFetching: false });
  };

  const banUserHandler = (event, userId) => {
    // fetch, ban user (TODO: Add banned feature, and display it next to the name, also prevent them from doing anything. They could be unbanned in future for instance...)
    const url = `${Config.serverUrl}/auth/ban?userId=${userId}&ban=1`;
    fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true
      }
    })
      .then((response) => {
        if (response.status === 200) return response.json();
        throw new Error("failed to authenticate user");
      })
      .then((responseJson) => {
        if (!responseJson.error) {
          // Worked!
          window.reload();
        }
      })
      .catch((error) => {});
  };

  const replyHandler = (event, comment) => {
    let copy = allComments;
    for (var i in copy) {
      if (copy[i].id == comment.id) {
        copy[i].isReplying = true;
        justHitReplyRef = true;
        copy[i].initialText = "";
      } else {
        copy[i].isReplying = false;
      }
    }

    setAllComments({ comments: copy.filter((comment) => comment.id != -1), isFetching: false });
  };

  const handleActualDeleteHandler = () => {
    const url = `${Config.serverUrl}/auth/comments?commentId=${deleteId}`;
    fetch(url, {
      method: "DELETE",
      mode: "cors",
      credentials: "include"
    })
      .then((res) => res.json())
      .then((message) => {
        console.log(message);

        // Copy all comments
        let copy = allComments;
        console.log(copy);
        setAllComments({ comments: allComments.filter((comment) => comment.id != deleteId), isFetching: false });
        setDeleteOpen(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteHandler = (event, id) => {
    event.preventDefault();
    setDeleteOpen(true);
    deleteId = id;
  };

  let justHitReplyRef = false;
  const replyRef = React.useRef(null);

  React.useEffect(() => {
  if (replyRef.current) { //}.current && justHitReplyRef) {
      // Scroll to it
//      scrollToRef(replyRef);
      replyRef.current.scrollIntoView(false);
      justHitReplyRef = false;
    }
  });

  const handleEditorChange = (text, comment) => {
    const prev = comment.isEmptyContents;
    const isEmpty = !/\S/.test(text);
    const different = (typeof prev === "undefined" && isEmpty) || (typeof prev !== "undefined" && prev != isEmpty);

    if (different) {
      let copy = allComments;
      for (var i in copy) {
        if (copy[i].id == comment.id) {
          copy[i].isEmptyContents = !/\S/.test(text);
          console.log(copy[i].isEmptyContents);
          break;
        }
      }

      setAllComments({ comments: copy.filter((comment) => comment.id != -1), isFetching: false });
    }
  };

  const nonReplyToComments = allComments.filter((comment) => !comment.replyId || comment.replyId == 0);

  const getButtons = (comment, replyHandler1) => {
    let buttons = [];
    if (user) {
      if (comment.userId != user.id) {
        buttons.push(
          <Button variant="contained" className={classes.button} color="secondary" onClick={(event) => replyHandler1(event, comment)}>
            Reply
          </Button>
        );
        if (user.isAdmin) {
          buttons.push(
            <Button variant="contained" className={classes.button} color="secondary" onClick={(event) => banUserHandler(event, comment.userId)}>
              Ban
            </Button>
          );
        }
      }

      if (comment.userId == user.id || user.isAdmin) {
        buttons.push(
          <Fragment>
            <Button variant="contained" className={classes.button} color="secondary" onClick={(event) => editHandler(event, comment)}>
              Edit
            </Button>
            <Button variant="contained" className={classes.button} color="secondary" onClick={(event) => deleteHandler(event, comment.id)}>
              Delete
            </Button>
          </Fragment>
        );
      }
    }
    return buttons;
  };

  const makeComment = (comment, replyHandler, listTopClass) => {
    return <Fragment>

    <ListItem key={comment.id} className={listTopClass} alignItems="flex-start">
    <ListItemAvatar>
      <Avatar to={"/profile?id=" + comment.userId} component={RouterLink} alt="avatar" src={comment.avatarURL} />
    </ListItemAvatar>
    <ListItemText
      primary={
        <Typography className={classes.fonts}>
          {comment.name}{" "}
          <Typography variant="overline" display="inline">
            {comment.isAdmin ? "(Admin)" : comment.isBanned ? "(Banned)" : ""}
          </Typography>
        </Typography>
      }
      secondary={
        <>
          <Typography component="span" variant="body2" className={classes.inline} color="textPrimary">
            {moment.utc(new Date(comment.updatedAt)).fromNow()}
          </Typography>
          {getButtons(comment, replyHandler)}
        </>
      }
    />
  </ListItem>
  <CppMdEditor
    updateMdEditor={(mdEditor) => (comment.mdEditor = mdEditor)}
    initialText={comment.text}
    md={comment.isEditing}
    menu={false}
    fullHeight={!comment.isEditing}
    handleEditorChange={({ text }) => handleEditorChange(text, comment)}
    usingLightTheme={usingLightTheme}
  />
  {comment.isEditing ? (
    <Fragment>
      <Button
        variant="contained"
        className={classes.editButton}
        onClick={(event) => updateHandler(event, comment)}
        disabled={comment.isEmptyContents}
        color="secondary"
      >
        Update
      </Button>
      <Button variant="contained" className={classes.editButton} color="secondary" onClick={(event) => cancelHandler(event, comment)}>
        Cancel
      </Button>
    </Fragment>
  ) : null}
  </Fragment>
  };

  return (
    <Fragment>
      <Typography>Comments</Typography>
      <Alert dialogTitle={"Error"} text={"Too many characters in reply"} isOpen={errOpen} setIsOpen={setErrOpen} />
      <ProcessCancelDialog
        dialogTitle={"Are you absolutely sure?"}
        text={"Deleting this post is irrevisble and will remove all replies too"}
        isOpen={deleteOpen}
        successLabel="Delete"
        cancelLabel="Cancel"
        setIsOpen={setDeleteOpen}
        handleSuccess={handleActualDeleteHandler}
      />
      <List className={classes.root}>
        {nonReplyToComments.map((comment) => {
          // Find responses which are tied to this comment
          const replyToComments = allComments.filter((comment1) => comment1.replyId && comment1.replyId == comment.id);

          return (
            <div className={classes.list} key={comment.id}>
            {makeComment(comment, replyHandler, classes.listTop)}
            {(replyToComments.length > 0) ? <Divider /> : null }
              <div className={(replyToComments.length > 0) ? classes.reply : ""}>
                {
                  // This is replies to this comment
                  replyToComments.map((comment) => {
                    return (
                      <div className={classes.replyList} key={comment.id}>
                      {makeComment(comment, replyToReplyHandler, classes.replyListTop)}
                      </div>);
                  })
                }
                {comment.isReplying ? (
                  <div ref={replyRef}>
                    <PostReply
                      groupId={groupId}
                      user={user}
                      replyId={comment.id}
                      initialText={comment.initialText}
                      postHandler={(newComment) => onPostUpdateHandler(comment, newComment)}
                      usingLightTheme={usingLightTheme}
                    />
                  </div>
                ) : null}
              </div>
            </div>
          );
        })}
      </List>
    </Fragment>
  );
};

export default Comment;
