import React, { Fragment } from "react";
import { Typography, Link } from "@material-ui/core";

export default ({ title }) => {
  return (
    <Fragment>
      <Typography variant="h3">{title}</Typography>
      <Typography>The video shown in here took some graphics/sounds with creative commons license from: </Typography>
      <ul>
      <li><Link color="secondary" href="https://www.vecteezy.com/vector-art/109107-laser-guns-vector-illustrations">https://www.vecteezy.com/vector-art/109107-laser-guns-vector-illustrations</Link></li>
      <li><Link color="secondary" href="https://www.flaticon.com/free-icon/wafer_1076243">https://www.flaticon.com/free-icon/wafer_1076243</Link></li>
      <li><Link color="secondary" href="https://www.vexels.com/png-svg/preview/198054/hammer-flat">https://www.vexels.com/png-svg/preview/198054/hammer-flat</Link></li>
      <li><Link color="secondary" href="https://www.flaticon.com/free-icon/sparkles_616493">https://www.flaticon.com/free-icon/sparkles_616493</Link></li>
      <li><Link color="secondary" href="https://pngtree.com/element/down?id=Mzc2Njc4MA==&amp;type=1&amp;time=1590258563&amp;token=N2FiOTY1NTc2Y2ExN2UyY2RiYjVlN2ZjNTYxZjNlODI=">{`https://pngtree.com/element/down?id=Mzc2Njc4MA==&type=1&time=1590258563&token=N2FiOTY1NTc2Y2ExN2UyY2RiYjVlN2ZjNTYxZjNlODI=`}</Link></li>
      <li><Link color="secondary" href="https://freesound.org/people/zagi2/sounds/206090">https://freesound.org/people/zagi2/sounds/206090</Link></li>
      <li><Link color="secondary" href="https://freesound.org/people/DDmyzik/sounds/455419/">https://freesound.org/people/DDmyzik/sounds/455419/</Link></li>
      <li><Link color="secondary" href="https://freesound.org/people/jsbarrett/sounds/321261/">https://freesound.org/people/jsbarrett/sounds/321261/</Link></li>
      <li><Link color="secondary" href="https://freesound.org/people/Timbre/sounds/77738/">https://freesound.org/people/Timbre/sounds/77738/</Link></li>
      <li><Link color="secondary" href="https://www.hiclipart.com/free-transparent-background-png-clipart-mfswaq">https://www.hiclipart.com/free-transparent-background-png-clipart-mfswaq</Link></li>
      <li><Link color="secondary" href="https://pngtree.com/freepng/a-white-cat-sleeps-on-its-stomach_4287137.html">https://pngtree.com/freepng/a-white-cat-sleeps-on-its-stomach_4287137.html</Link></li>
      </ul>
      <Typography>This site was created in React (w/ router) and a MongoDB/Express backend.</Typography>
    </Fragment>
  );
};
