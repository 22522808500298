import React, { Fragment } from 'react'
import { Typography, Grid, Hidden } from '@material-ui/core'
import BlogPost from '../Components/BlogPost';
import moment from 'moment';

import CppUniverseIcon from '../cpp_universe.jpg';
import CppMoonIcon from "../Logo_512.jpg";

export default props => {

	return <Fragment>
		<Hidden mdDown implementation="css">
			<img width="100%" src={CppUniverseIcon} />
		</Hidden>
		<Typography variant="h4">The home of everything related to modern C++</Typography>
		<Typography>This site is currently in-progress and currently has a number of items to improve effectiveness when using C++20. In the future we hope to have sections on heterogenous programming (OpenCL/SyCL), OpenGL 3D graphics, video game development, distributed networks, cryptocurrency, trading engines (low latency programming), compiler engineering, competitive programming algorithms, third party libraries like gRPC, RapidJSON, Boost and much much more.</Typography>

		<div>
			<Grid container justify="center" spacing={3} alignItems="center" direction="row" alignItems="stretch">
				<Grid item xs={12} md={6} align="center">
					<BlogPost title={"C++ Universe is born"} img={CppMoonIcon} text="The inception of a site dedicated to teach new and existing users how to code efficently with the latest C++ standards" link="Read more" date={moment(new Date(2020, 7, 1)).fromNow()} route="/Blog/inception" />
				</Grid>
				{/*<Grid item xs={12} md={6} align="center">
					<BlogPost title={"New Quiz"} img={WesleyIcon} text="123" link="Fuuuu" date={new Date(2020, 5, 9).toString()} route="/Quiz/cpp20_1" />
				</Grid>
				<Grid item xs={12} md={6} align="center">
					<BlogPost title={"New Quiz"} img={WesleyIcon} text="Penises" link="Try the quiz12312312" date={new Date(2020, 5, 9).toString()} route="/Quiz/cpp20_1" />
</Grid>*/}
			</Grid>
		</div>
	</Fragment>
}
