import React, { Fragment } from 'react'
import { Typography, makeStyles, Card, CardMedia, CardActionArea, CardContent, Chip } from '@material-ui/core'
import YouTube from 'react-youtube';

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
});

export default ({title}) => {

	const classes = useStyles();

	const opts = {
		height: '390',
		width: '640',
		playerVars: {
			// https://developers.google.com/youtube/player_parameters
			autoplay: 0,
		},
	};

	return <Fragment>
		<Typography>{title}</Typography>
    <Card className={classes.root}>
      <CardActionArea>
        <CardMedia
          className={classes.media}
          image="/Logo_512.jpg"
          title="Create your own cryptocurrency"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            Protocol buffers, Flat buffers, gRPC, OpenCL, OpenGL/GLSL, SDL, REST Server, Websocket Server, MQTT, Bullet Engine. Kafka, Embedded, ImGUI, NANO Protocol, Compiler
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            TODO
          </Typography>
						<Chip size="small" label="C++20" />
						<Chip size="small" label="Cryptocurrency" />
        </CardContent>
      </CardActionArea>
    </Card>
		<YouTube videoId="mtA94WAxkPM" opts={opts} />
	</Fragment>
}