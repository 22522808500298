import React, { Fragment } from 'react'
import { Typography, Avatar } from '@material-ui/core'
import Config from "../config.js"

const queryString = require('query-string');

export default (props) => {

	const [profileData, setProfileData] = React.useState ({
		loading: true,
		profile: null
	});

	const { loading, profile } = profileData;

	const parsedQueryParams = queryString.parse(props.location.search);

  React.useEffect(() => {
		const id = parsedQueryParams.id ? parsedQueryParams.id : props.user ? props.user.id : null;
		if (id) {
			const url = `${Config.serverUrl}/profile?id=${id}`;
			// Fetch profile data
			fetch(url, {
				method: "GET",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
					"Access-Control-Allow-Credentials": true
				}
			})
			.then(response => {			
				if (response.status === 200) return response.json();
				console.log (response);
				throw new Error("failed to authenticate user");
			})
			.then(responseJson => {
				console.log(responseJson);

				//res.json({ username, avatarURL points } );

				setProfileData({
					loading: false,
					profile: responseJson
				});
			})
			.catch(error => {			
				//setError ("Failed to authenticate user");
			});
	}
}, [props.user]);

	let output;
		if (loading) {
			output = <Typography>Loading...</Typography>
		}
		else
		{
			output = <Fragment>
				<Typography>{profile.username}</Typography>
				<Avatar src={profile.avatarURL} />
				<Typography>Points: {profile.points}</Typography>
				</Fragment>
		}
	return <Fragment>
		<Typography>{/*props.title*/}</Typography>
		{output}
	</Fragment>
}