import React, { Fragment } from "react";
import { Typography } from "@material-ui/core";

import Highlight from "react-highlight.js";
import Comments from "../../Components/Comments";
import PostReply from "../../Components/PostReply";
import { onPostUpdateHandler, inlineCodeStyle } from "./common.js";

const groupId = 43;

export default ({ user, title, usingLightTheme }) => {
  const [commentState, setComments] = React.useState({
    comments: [],
    isFetching: true
  });

  const classes = inlineCodeStyle ();

  return (
    <Fragment>
      <Typography variant="h5">{title}</Typography>

      <Typography>A generator can compute values in an infinite (or finite) sequence on demand while encapsulating the state for continuation. Let’s see how this would look pre-C++20:</Typography>
      <Highlight language="c++">
{`struct fibonacii {
  int next() {
    auto c = a + b;
    a = b;
    b = c;
    return c;
  }

private:
  int a{0};
  int b{1};
};

int main () {
  auto fib = fibonacii();

  for (int i = 0; i < 10; ++i) {
      std::cout << fib.next() << std::endl; // 1 1 2 3 5 8 13 21 34 55
  }
}`}
</Highlight>
<Typography>It stores <Highlight language="c++" className={classes.inline}>a</Highlight> and <Highlight language="c++" className={classes.inline}>b</Highlight> as member variables to maintain the state for the next iteration. In C++20 we can get coroutines to manage this state for us. The <Highlight language="c++" className={classes.inline}>co_yield</Highlight> keyword is used to synchronously return a value from inside a coroutine.</Typography>
<Highlight language="c++" className={classes.inline}>co_yield expr</Highlight> is equivalent to:
<Highlight language="c++">
{`co_await promise.yield_value(expr)`}
</Highlight>
<Typography>The below code shows the equivalent generator as a coroutine:</Typography>
<Highlight language="c++">
{`struct generator {
  struct promise_type {
    constexpr std::suspend_always initial_suspend() const noexcept {
      return {};
    }
    constexpr std::suspend_always final_suspend() const noexcept {
      return {};
    }
    void unhandled_exception() {}
    generator get_return_object() noexcept {
      return std::coroutine_handle<promise_type>::from_promise(*this);
    }
    void return_void() {}
    auto yield_value(int value) {
      current_value = value;
      return std::suspend_always{};
    }

    int current_value;
  };

  generator(std::coroutine_handle<promise_type> handle) :
  handle(handle) {}

  int next() {
    handle.resume();
    return handle.promise().current_value;
  }

private:
  std::coroutine_handle<promise_type> handle;
};

generator fibonacii() {
  int a = 0;
  int b = 1;
  while (true) {
    co_yield b;
    auto c = a + b;
    a = b;
    b = c;
  }
}

int main () {
  auto fib = fibonacii();

  for (int i = 0; i < 10; ++i) {
    std::cout << fib.next() << std::endl;
  }
}`}
</Highlight>
<Typography>This looks a lot more complicated though… so why would we want to do this? Well C++20 doesn’t come with any built-in generators, so we need to make them ourselves. Those can largely be reused (once made into a template). The actual Fibonacci coroutine is exactly how we would write it as a normal function so we get back the syntax we are used to, without having to create an extra class for that purpose. Until C++23 we will have to write our own, which means we get some flexibility on the interface, however the following is suggested:</Typography>
<br />
<Typography> - Generators which are finite should return <Highlight language="c++" className={classes.inline}>std::optional</Highlight></Typography>
<Typography> - Infinite sequence generators should return value semantics</Typography>
<Typography> - Give generators iterator semantics so that ranged-for & ranges can be used</Typography>
<Typography> - Use async generators when they depend on values being produced asynchronously</Typography>
<br />
<Typography>To prevent <Highlight language="c++" className={classes.inline}>co_await</Highlight> being used with this generator then delete <Highlight language="c++" className={classes.inline}>await_transform</Highlight>:</Typography>
<Highlight language="c++">
{`template<typename U>
std::suspend_never await_transform(U&& value) = delete;`}
</Highlight>

      <Comments groupId={groupId} user={user} usingLightTheme={usingLightTheme} commentState={commentState} setComments={setComments} />
      <PostReply
        groupId={groupId}
        user={user}
        replyId={0}
        usingLightTheme={usingLightTheme}
        postHandler={(newComment) => onPostUpdateHandler(user, groupId, commentState.comments, setComments, newComment)}
      />
    </Fragment>
  );
};
