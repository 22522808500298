import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Link as RouterLink } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  media: {
    height: 350,
    objectFit: "contain"
  },
  root: {
    maxWidth: 700,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%"
  },
  button: {}
}));

export default ({ title, img, text, link, date, route }) => {
  const classes = useStyles();

  return (
    <Card className={classes.root} >
      <CardActionArea to={route} component={RouterLink}>
        <CardMedia className={classes.media} image={img} title={title} component="img" />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {title}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {date}
            <br />
            {text}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button size="small" variant="contained" color="secondary" to={route} component={RouterLink} className={classes.button}>
          {link}
        </Button>
      </CardActions>
    </Card>
  );
};
