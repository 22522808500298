import React from "react";
import MdEditor from "react-markdown-editor-lite";

var hljs = require("highlight.js");
const mdParser = require("markdown-it")({
  highlight: function (str, lang) {
    if (lang && hljs.getLanguage(lang)) {
      try {
        return '<pre class="hljs"><code>' + hljs.highlight(lang, str, true).value + "</code></pre>";
      } catch (__) {}
    } else {
      // Default to C++ if nothing is specified
      try {
        return '<pre class="hljs c++ arduino my_pre"><code class="my_code">' + hljs.highlight("c++", str, true).value + "</code></pre>";
      } catch (__) {}
    }
    return '<pre class="hljs"><code>' + mdParser.utils.escapeHtml(str) + "</code></pre>";
  },
  breaks: true
});//.disable(["link"]);

const mdParserLight = require("markdown-it")({
  highlight: function (str, lang) {
    if (lang && hljs.getLanguage(lang)) {
      try {
        return '<pre class="hljs"><code>' + hljs.highlight(lang, str, true).value + "</code></pre>";
      } catch (__) {}
    } else {
      // Default to C++ if nothing is specified
      try {
        return '<pre class="hljs c++"><code class="my_code">' + hljs.highlight("c++", str, true).value + "</code></pre>";
      } catch (__) {}
    }
    return '<pre class="hljs"><code>' + mdParserLight.utils.escapeHtml(str) + "</code></pre>";
  },
  breaks: true
});//.disable(["link"]);

export const CppMdEditor = ({ initialText, updateMdEditor, handleEditorChange, menu, md, fullHeight, usingLightTheme }) => {
	const [mdEditor, setMdEditor] = React.useState (null);

  const htmlStyle = usingLightTheme ? "my-custom-html-style-light" : "my-custom-html-style";
 
  let editor = (
    <div class="penis">
    <MdEditor
      ref={(node) => (setMdEditor (node))}
      value={initialText ? initialText : ""}
      renderHTML={(text) => usingLightTheme ? mdParserLight.render(text) : mdParser.render(text)}
      plugins={["header", "fonts", "logger"]}
      style={{ height: !fullHeight ? "500px" : "100%", width: "100%" }}
      placeholder="Insert reply (this uses markdown)"
      config={{
        view: {
          menu: menu,
          md: md,
          html: !md
        },
        canView: {
          fullScreen: false,
          hideMenu: false
        },

        htmlClass: htmlStyle,
        allowPasteImage: false,
        table: {
          maxRow: 5,
          maxCol: 6
        }
      }}
      onChange={handleEditorChange}
    />
    </div>
  );

  updateMdEditor(mdEditor);

  return editor;
};
