import React, {Fragment} from 'react';
import Header from './Header';
import Footer from './Footer';
import Drawer from './Drawer';
import Config from './config.js';
import { ThemeProvider, createMuiTheme, useMediaQuery } from '@material-ui/core';

export default props => {

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [user, setUser] = React.useState(null);
  const [error, setError] = React.useState(null);

  React.useEffect(() => {
    console.log ("useEffect initiated");
    fetch(`${Config.serverUrl}/auth/login/success`, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true
      }
    })
      .then(response => {
        if (response.status === 200) return response.json();
        throw new Error("failed to authenticate user");
      })
      .then(responseJson => {
        console.log(responseJson)
        setUser (responseJson.user);
      })
      .catch(error => {
        setUser (null);
        setError ("Failed to authenticate user");
      });
  }, []);

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)'); 

  const [usingLightTheme, setUsingLightTheme] = React.useState (false); // Currently hardcoding dark theme prefersDarkMode);

  const lightTheme = {
    palette: {
      type: 'light',
//      primary: { main: '#37b44e' },
//      secondary: { main: '#000' },
    },
 };

 const darkTheme = {
    palette: {
      type: 'dark',
      primary: { main: '#0c1c0a' },
      secondary: { main: '#2f7327' }
    },
 };

return <Fragment>
  <ThemeProvider theme={usingLightTheme ? createMuiTheme (lightTheme) : createMuiTheme (darkTheme)}>
    <Header {...{mobileOpen, setMobileOpen, usingLightTheme, setUsingLightTheme, user, error, setUser}} />
    <Drawer {...{mobileOpen, setMobileOpen, usingLightTheme, user, error}} />
  </ThemeProvider>
  </Fragment>
}