import React, { Fragment } from 'react'
import {ListItem, List, Divider, ListItemText, ListItemIcon, Typography, CssBaseline, Hidden, Drawer, Icon} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { conceptsCppPages, rangesCppPages, coroutinesCppPages } from './EverydayCpp20/store'
import EverydayCpp20_actual from './EverydayCpp20_actual'

import { useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

const drawerWidth = 260;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
  //  [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0,
  //  },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
		flexGrow: 1,
//		marginRight:drawerWidth,
//    padding: theme.spacing(3),
  },
}));

const ScrollIntoView = ({ children, location }) => {
  const prevLocation = useRef();

  useEffect(() => {
    if (prevLocation.current !== location.pathname) {
      window.scrollTo(0, 0);
      prevLocation.current = location.pathname;
    }
  }, [location]);

  return children;
};

ScrollIntoView.propTypes = {
  children: PropTypes.node,
  location: PropTypes.object
};

const ScrollIntoView1 = withRouter(ScrollIntoView);

export default ({title, user, usingLightTheme}) => {

	function Error() {
		return <h1>Page not found!</h1>
	};

	let match = useRouteMatch();
	const classes = useStyles();

  const drawer = (
			<div>
      <div className={classes.toolbar} />
      <Divider />
      <List>
			<ListItem button key={"penis"}>
            <ListItemIcon><Icon /></ListItemIcon>
            <ListItemText primary={"Item 1"} />
          </ListItem>
					<ListItem button key={"penis1"}>
            <ListItemIcon><Icon /></ListItemIcon>
            <ListItemText primary={"Item 2"} />
          </ListItem>
        {/*pages.map(({ title, route, Icon }) => 
          <ListItem to={route} component={RouterLink} onClick={onHandlerClick} button key={title}>
            <ListItemIcon><Icon /></ListItemIcon>
            <ListItemText primary={title} />
          </ListItem>
	)*/}
      </List>
      <Divider />
      <Divider />
			</div>
	);

  var merged = [...conceptsCppPages, ...rangesCppPages, ...coroutinesCppPages];

	return <Fragment>
		<main className={classes.content} >
		<Typography variant="h5">{title}</Typography>
    <ScrollIntoView1>
		<Switch>
				{merged.map(({ title, route, Comp }) => 
					<Route path={`${match.path}/${route}`} exact render={(props) => <Comp {...props} usingLightTheme={usingLightTheme} user={user} title={title} />} />
				)}
			<Route path={match.path} component={EverydayCpp20_actual} />
			<Route component={Error} />
		</Switch>
    </ScrollIntoView1>
		</main>


        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={'right'}
            open={false /*mobileOpen*/}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
						}}
						anchor={'right'}
            variant="temporary"
            open={false /*Not currently using it*/}
          >
            {drawer}
          </Drawer>
        </Hidden>



	</Fragment>
}