import React, { Fragment } from "react";
import { Typography, Button, Toolbar, AppBar, Tabs, Tab, Box, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Config from "../config";
import ReactDOM from 'react-dom'; 

// import style manually
import "react-markdown-editor-lite/lib/index.css";

import PropTypes from "prop-types";
import { CppMdEditor } from "./MDParser.js";
import Alert from "./Alert";

export default ({ groupId, user, replyId, initialText, postHandler, usingLightTheme }) => {
  let mdEditor = null;

  const [isEmptyContents, setIsEmptyContents] = React.useState(true);
  const [forceDisableButton, setForceDisableButton] = React.useState(false);
  const [errOpen, setErrOpen] = React.useState(false);

  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
      border: "1px solid #222",
    },
    postReplyButton: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    },
    appbar: {
      backgroundColor: "#303030",
     // paddingBottom: "2px",

      //      display: "inline"
      //    borderRadius: 3,
   //   border: "1px solid #222",
   //   borderBottomStyle: "none",
     // boxShadow: "2 3px 5px 2px rgba(255, 105, 135, .3)"
    },
    default_tabStyle:{
      color: 'grey',
      fontSize:12,
      backgroundColor: theme.palette.background.paper
     },
     
      active_tabStyle:{
      fontSize:16,
      fontWeight: "bold",
      color: theme.palette.secondary.main,
      backgroundColor: 'white',
     }

  }));

  function handleEditorChange({ html, text }) {
    setIsEmptyContents(!/\S/.test(text));
  }

  const onPostReply = () => {
    if (mdEditor) {
      let url = `${Config.serverUrl}/auth/comments?groupId=${groupId}`;
      if (replyId) {
        console.log("replyId", replyId);
        url += `&replyId=${replyId}`;
      }

      setForceDisableButton(true);

      // Change to comment view if not there already and clear it
      if (value == 1) {
        // We are in preview mode, so change back
        handleChange(null, 0);
        //				setValue(0);
        //				setSelectedID (0);
        //					mdEditor.setView({
        //						menu: false,
        //						md: true,
        //						html: false
        //					});
      }

      // These belong to you (get from user).. name, avatarURL, This should be the

      // Construct the newComment

      // Clear the field
      fetch(url, {
        method: "POST",
        mode: "cors",
        credentials: "include",
        headers: {
          "Content-Type": "text/plain",
          "Access-Control-Allow-Credentials": true
        },
        body: mdEditor.getMdValue()
      })
        .then((response) => response.json())
        .then((response) => {
          if (!response.hasOwnProperty("error")) {
            let text = mdEditor.getMdValue();
            mdEditor.setText(""); // save text

            const newCommentId = response.data;

            let obj = {};
            if (replyId) {
              obj.replyId = replyId;
            }
            obj.text = text;
            obj.id = newCommentId;

            postHandler(obj);
          } else {
            setErrOpen(true);
          }

          setForceDisableButton(false);
        });
    } else {
      alert("mdEditor is null");
    }
  };

  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue == 1) {
      // Preview mode
      if (mdEditor) {
        mdEditor.setView({
          menu: false,
          md: false,
          html: true
        });
      }
    } else {
      // Normal post comment mode
      if (mdEditor) {
        mdEditor.setView({
          menu: true,
          md: true,
          html: false
        });
      }
    }
  };

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </Typography>
    );
  };

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
  };

  return (
    <Fragment>
      {user ? (
        <Fragment>
          <div className={classes.root}>
          <Box square className={classes.appbar}>
              <Tabs value={value} onChange={handleChange} indicatorColor="secondary" textColor="#777" aria-label="simple tabs example">
                <Tab label="Write" className={value===0 ? classes.active_tabStyle : classes.default_tabStyle} />
                <Tab label="Preview" className={value===1 ? classes.active_tabStyle : classes.default_tabStyle} />
              </Tabs>
            </Box>

            <CppMdEditor
              updateMdEditor={(mdEditor_a) => (mdEditor = mdEditor_a)}
              initialText={initialText}
              md={true}
              menu={true}
              handleEditorChange={handleEditorChange}
              usingLightTheme={usingLightTheme}
            />
          </div>
          <Button
            variant="contained"
            color="secondary"
            className={classes.postReplyButton}
            onClick={onPostReply}
            disabled={isEmptyContents || forceDisableButton}
          >
            Post Reply
          </Button>
          <Alert dialogTitle={"Error"} text={"Too many characters in post"} isOpen={errOpen} setIsOpen={setErrOpen} />
        </Fragment>
      ) : (
        <Typography>Log in to comment</Typography>
      )}
      </Fragment>
  );
};
