import React, { Fragment } from 'react'
import { Typography, Link } from '@material-ui/core'

export default ({ title }) => {

	return <Fragment>
		<Typography variant="h3">{title}</Typography>
		<Typography>This site was created in 2020 by Wesley Shillingford, a C++ expert with almost a decade of professional C++ experience in 3D CAD/CAM,
			 safety critical testing software and cryptocurrency protocol development. As a hobbyist he has developed games, websites, mobile apps and has been doing video tutorials for over 10 years. For hoppyist <Link href="http://blurinestudios.com/portfolio.php" color="secondary">portfolio</Link></Typography>
	</Fragment>
}